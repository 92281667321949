import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  CustomBarChart, CustomTooltip, FlexContainer, SkeletonChart, SubtitlePage, TextError
} from 'components';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import { FluidBalancesService, StocksService } from 'services';
import styled from 'styled-components';
import { formatDate, FormHelper, translate } from 'utils/helpers';
import { FluidDetail } from './FluidDetail';

const StocksDashboardContainer = styled.section`
  padding-top: 2rem;

  .MuiToggleButtonGroup-root {
    width: auto;
  }
`;

export const StocksDashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [detailFluidSelected, setDetailFluidSelected] = useState(null);
  const [allFluids, setAllFluids] = useState(null);
  const [detailedFluids, setDetailedFluids] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isDetailedMode, setIsDetailedMode] = useState(true);

  const [yearSelected, setYearSelected] = useState(null);
  const [years, setYears] = useState([]);

  const getFluidDetail = (data) => {
    if (data && data.id) {
      setIsLoadingDetail(true);
      return StocksService.getDetailStock(data.id, yearSelected?.value).then((response) => {
        setDetailFluidSelected({ ...response, fluidName: data.fluidLabel });
        setTimeout(() => {
          document.getElementById('fluidDetail') && document.getElementById('selectFluidYear').scrollIntoView({ behavior: 'smooth' });
        }, 500);
      }).finally(() => setIsLoadingDetail(false));
    }

    return enqueueSnackbar(translate('errors.noFluidDetail'), { variant: 'error' });
  };

  useEffect(() => {
    if (yearSelected?.value && detailFluidSelected?.fluidHashId) {
      getFluidDetail({ hashId: detailFluidSelected.fluidHashId, fluidLabel: detailFluidSelected.fluidName });
    }
    // eslint-disable-next-line
  }, [yearSelected]);

  useEffect(() => {
    FluidBalancesService.getYears().then((response) => {
      if (response && response.length > 0) {
        const yearsFormatted = FormHelper.SELECT_ADAPTOR_TO_FRONT_MULTI({ selectItems: response });
        setYears(yearsFormatted);
        setYearSelected(yearsFormatted.find(year => Number(year.key) === new Date().getFullYear()));
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    StocksService.getAllStocks().then((stocks) => {
      const stockAll = stocks.map(data => ({
        fluidLabel: data.fluidLabel,
        [translate('stock.total')]: data.totalAmount,
        id: data.fluidId
      }));

      const stockDetailed = stocks.map(data => ({
        fluidLabel: data.fluidLabel,
        [translate('stock.newFluid.label')]: data.newFluidAmount,
        [translate('stock.regeneratedFluid.label')]: data.regeneratedFluidAmount,
        [translate('stock.reusableFluid.label')]: data.reusableFluidAmount,
        [translate('stock.usedFluid.label')]: data.usedFluidAmount,
        id: data.fluidId
      }));

      stockAll && setAllFluids(stockAll);
      stockDetailed && setDetailedFluids(stockDetailed);
    }).finally(() => setIsLoading(false));
  }, []);

  const clearFluidDetail = useCallback(() => setDetailFluidSelected(null), []);

  const handleChangeMode = useCallback((event, value) => {
    if (value === null) return;
    setIsDetailedMode(value);
  }, []);

  return (
    <StocksDashboardContainer>
      {isLoading && <SkeletonChart />}
      {!isLoading && allFluids && allFluids.length > 0 ? (
        <div data-tour="step-dashboard-graph" id="stocksDashboard">
          <FlexContainer alignItems="center" flexWrap="wrap" justifyContent="space-between">
            <SubtitlePage>
              {`${translate('pageStocks.fluidAvailable')} ${formatDate(new Date())} (kg)`}
              <CustomTooltip text={translate('pageStocks.tooltipFluidDetail')} />
            </SubtitlePage>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={isDetailedMode}
              onChange={handleChangeMode}
            >
              <ToggleButton
                aria-label={translate('pageStocks.details')}
                value
              >
                {translate('pageStocks.details')}
              </ToggleButton>
              <ToggleButton
                aria-label={translate('pageStocks.general')}
                value={false}
              >
                {translate('pageStocks.general')}
              </ToggleButton>
            </ToggleButtonGroup>
          </FlexContainer>

          {isDetailedMode ? (
            <CustomBarChart
              barKeys={[{
                key: translate('stock.newFluid.label'),
                fill: 'var(--grey-light)',
                stackId: 'a'
              }, {
                key: translate('stock.regeneratedFluid.label'),
                fill: 'var(--grey)',
                stackId: 'a'
              }, {
                key: translate('stock.reusableFluid.label'),
                fill: 'var(--blue)',
                stackId: 'a'
              }, {
                key: translate('stock.usedFluid.label'),
                fill: 'var(--turquoise)',
                stackId: 'a'
              }]}
              chartData={detailedFluids}
              labelFontSize={isMobileOnly ? '1.2rem' : '2rem'}
              layout="vertical"
              xAxisType="number"
              yAxisKey="fluidLabel"
              yAxisType="category"
              onClick={getFluidDetail}
            />
          ) : (
            <CustomBarChart
              barKeys={[{
                key: translate('stock.total'),
                fill: 'var(--blue)'
              }]}
              chartData={allFluids}
              hideLegend
              labelFontSize={isMobileOnly ? '1.2rem' : '2rem'}
              layout="vertical"
              xAxisType="number"
              yAxisKey="fluidLabel"
              yAxisType="category"
              onClick={getFluidDetail}
            />
          )}
        </div>
      ) : !isLoading && <TextError>{translate('errors.noRegisteredBottle')}</TextError>}

      {isLoadingDetail ? (
        <SkeletonChart />
      ) : detailFluidSelected && (
        <>
          <Select
            id="selectFluidYear"
            isClearable={false}
            noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
            options={years}
            placeholder={translate('common.selectYear')}
            value={yearSelected || ''}
            onChange={value => setYearSelected(value)}
          />
          <FluidDetail {...detailFluidSelected} clearFluidDetail={clearFluidDetail} yearSelected={yearSelected} />
        </>
      )}
    </StocksDashboardContainer>
  );
};
