import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  faAirConditioner,
  faBalanceScale, faBuilding, faCalendarAlt, faClock, faDownload, faEdit,
  faFilePdf, faPhone, faPlay, faSpinner, faTag, faTint, faUser, faUserTie
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TableCell, Tooltip } from '@material-ui/core';
import {
  ButtonBlue, ButtonBlueLight, FlexContainer, FontIcon, GenericTable, InterventionListItemDetails, InterventionMap, InterventionType, SkeletonDetailIntervention, SubtitlePage, Text, TextAddress, TextError, TextMultiline, TitlePage, Translate, Wrapper
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { FluidManipulationEntryView, InterventionView, LeakEntryView } from 'models';
import { getIdentifier } from 'models/detail/EquipmentView';
import { FormFrontView } from 'models/detail/FormFrontView';
import { InterventionFrontView } from 'models/detail/InterventionFrontView';
import { InterventionStatusView } from 'models/detail/InterventionStatusView';
import { getAddressFromIntervention } from 'models/detail/InterventionView';
import { useSnackbar } from 'notistack';
import {
  Link, useHistory, useLocation, useParams
} from 'react-router-dom';
import { InterventionsService } from 'services';
import shortid from 'shortid';
import { ROUTES, StatusList } from 'utils/constants';
import {
  BottleHelper, DocumentHelper,
  FormHelper, InterventionHelper,
  formatDateString,
  translate
} from 'utils/helpers';

const InterventionDetailBody = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  table {
    @media (min-width: 768px) {
      min-width: 700px;
    }

    img {
      max-width: 50px;
    }
  }
`;

const InterventionDetailCard = styled.article`
  min-height: 150px;
  padding: 1rem;
  border-radius: 1.6rem;
  border: 1px solid #d3d2d6;

  &:last-of-type {
    h2 span {
      font-size: 2rem;
    }
  }

  h2 {
    margin-top: 0;
    color: var(--turquoise);
    font-size: 1.6rem;
    text-transform: uppercase;

    svg, > span {
      margin-right: 1rem;
      font-size: 1.6rem;
    }
  }

  p {
    margin: 0.5rem 0;

    svg {
      min-width: 16px;
      margin-right: 1rem;
    }
  }
`;

const InterventionDetailType = styled.div`
  margin-top: 2rem;
  padding-bottom: 1rem;
  background: rgba(0,0,0,0.04);
`;

export const InterventionDetail = observer(() => {
  const { userStore } = useStores();
  const history = useHistory();
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const location: any = useLocation();
  const { id: hashId } = useParams<any>();
  const { state = {} } = location;

  const [isLoading, setIsLoading] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [intervention, setIntervention] = useState<InterventionView>();
  const [storedInterventionForm, setStoredInterventionForm] = useState<FormFrontView>();
  const [outOfSyncStatus, setOutOfSyncStatus] = useState<InterventionStatusView>();
  const [INTERVENTION_STATUS, setInterventionStatus] = useState<StatusList>();

  const isNewIntervention = state.isNew || (hashId && Number(hashId.length) < 12);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    const buildIntervention: () => Promise<InterventionView> = async () => {
      const interventionDetailStored = await InterventionHelper.loadInterventionDetailStored(hashId);
      const interventionFormStored = await InterventionHelper.loadInterventionFormStored(hashId);
      setStoredInterventionForm(interventionFormStored);
      await InterventionHelper.loadInterventionStatuses().then(IS => setInterventionStatus(IS));

      if (userStore.isOffline || isNewIntervention) {
        // If the intervention is fully offline
        if (!interventionDetailStored) {
          // If the intervention isn't stored, return to the intervention list
          history.push(ROUTES.INTERVENTION.LIST);
          return null;
        }
        // Else, build the intervention using the stored data
        return {
          ...interventionDetailStored,
          form: FormHelper.BUILD(interventionFormStored)
        };
      }

      // Load the current data from the server
      return InterventionsService.getIntervention(hashId)
        .then((interventionDetailFromServer) => {
          if (!interventionDetailStored) {
            // If the intervention isn't stored, use the server data
            return interventionDetailFromServer;
          }
          // Else, update the stored intervention's synchronisation status
          const updatedIntervention: InterventionFrontView = {
            ...interventionDetailStored,
            operator: interventionDetailFromServer.operator,
            isOutOfSync: interventionDetailFromServer.isOutOfSync,
            outOfSyncStatus: interventionDetailFromServer.outOfSyncStatus
          };
          InterventionHelper.saveInterventionDetailStored(interventionDetailFromServer.hashId, updatedIntervention);
          // Build the intervention using the stored data
          return {
            ...updatedIntervention,
            form: FormHelper.BUILD(interventionFormStored)
          };
        });
    };

    buildIntervention().then(builtIntervention => {
      // If the intervention is out of sync, warn the user
      if (builtIntervention && builtIntervention.isOutOfSync) {
        open({
          type: 'WARNING',
          text: translate('warnings.intervention.outOfSync', {
            reason: InterventionHelper.getOutOfSyncReason(builtIntervention.outOfSyncStatus)
          }),
          buttonCancel: translate('button.cancel'),
          buttonConfirm: translate('button.deleteIntervention'),
          onConfirm: () => InterventionHelper.deleteInterventionStored(hashId)
            .then(() => {
              enqueueSnackbar(translate('confirms.intervention.delete'), { variant: 'success' });
              history.push(ROUTES.INTERVENTION.LIST);
            })
        });
        setOutOfSyncStatus(builtIntervention.outOfSyncStatus);
      }
      // Set the built intervention for display
      setIntervention(builtIntervention);
    }).finally(() => setIsLoading(false));
  }, [hashId, userStore.isOffline, isNewIntervention, enqueueSnackbar, open, history]);

  const saveAndStartIntervention = useCallback((interventionDetail: InterventionView) => {
    InterventionHelper.saveInterventionDetailAndForm(hashId, interventionDetail)
      .then(() => {
        const intFormStored = FormHelper.BACKEND_MAPPER(interventionDetail);
        history.push({
          pathname: `/interventions/${hashId}/inspection`,
          state: {
            currentStep: intFormStored.currentStep,
            needToBeStored: true,
            outOfSyncStatus,
            storedInterventionForm: intFormStored
          }
        });
      });
  }, [history, hashId, outOfSyncStatus]);

  const handleStartIntervention = useCallback(() => {
    const interventionStatusUpdated = {
      ...intervention,
      status: INTERVENTION_STATUS.ONGOING.key
    };

    if (intervention && intervention.isOutOfSync) return saveAndStartIntervention(interventionStatusUpdated);

    // If the intervention
    if (intervention && intervention.currentStep > 0) {
      return history.push({
        pathname: `/interventions/${hashId}/inspection`,
        state: {
          currentStep: intervention.currentStep,
          needToBeStored: true,
          outOfSyncStatus,
          storedInterventionForm
        }
      });
    }

    if (!userStore.isOffline && (intervention.status === INTERVENTION_STATUS.READY.key || intervention.status === INTERVENTION_STATUS.PENDING.key)) {
      const startIntervention = () => {
        setIsStarting(true);
        return InterventionsService.startIntervention(hashId, interventionStatusUpdated)
          .then(resp => saveAndStartIntervention(resp))
          .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }))
          .finally(() => setIsStarting(false));
      };

      if (!storedInterventionForm && intervention.status === INTERVENTION_STATUS.READY.key) {
        return open({
          type: 'WARNING',
          text: translate('warnings.intervention.interventionOnGoing'),
          buttonCancel: translate('button.cancel'),
          onConfirm: () => startIntervention()
        });
      }

      return startIntervention();
    }

    return saveAndStartIntervention(interventionStatusUpdated);
  }, [hashId, intervention, storedInterventionForm, userStore.isOffline, open, outOfSyncStatus,
    history, enqueueSnackbar, saveAndStartIntervention, INTERVENTION_STATUS]);

  const handleDownloadIntervention = useCallback(() => {
      const downloadIntervention = () => {
        // Fetch the intervention data and mark the intervention as downloaded
        InterventionsService.getInterventionDownload(hashId).then((intDownload) => {
          // Save the intervention data on the device
          InterventionHelper.saveInterventionDetailAndForm(hashId, intDownload)
            .then(() => {
              enqueueSnackbar(translate('confirms.intervention.downloaded'), { variant: 'success' });
              InterventionHelper.loadInterventionFormStored(hashId)
                .then((storedInt) => {
                  setIntervention(intDownload);
                  setStoredInterventionForm(storedInt);
                });
            })
            .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
        });
      };

      // If the intervention was already downloaded on another device, confirm the action first
      if (!storedInterventionForm && (intervention.status === INTERVENTION_STATUS.READY.key || intervention.status === INTERVENTION_STATUS.ONGOING.key)) {
        return open({
          type: 'WARNING',
          text: translate('warnings.intervention.interventionOnGoing'),
          buttonCancel: translate('button.cancel'),
          onConfirm: () => downloadIntervention()
        });
      }

      return downloadIntervention();
    }, [hashId, enqueueSnackbar, open, intervention, storedInterventionForm, INTERVENTION_STATUS]);

  const handleContinueIntervention = useCallback(() => {
    //
    if (intervention && (intervention.currentStep > 0 || intervention.isOutOfSync)) {
      return history.push({
        pathname: `/interventions/${hashId}/inspection`,
        state: {
          currentStep: intervention.currentStep,
          needToBeStored: true,
          outOfSyncStatus,
          storedInterventionForm
        }
      });
    }

    return InterventionHelper.loadInterventionFormStored(hashId)
      .then((formStored) => {
        if (formStored) {
          history.push({
            pathname: `/interventions/${hashId}/inspection`,
            state: {
              currentStep: intervention.currentStep,
              needToBeStored: true,
              outOfSyncStatus: intervention.outOfSyncStatus,
              storedInterventionForm: formStored
            }
          });
        } else {
          open({
            type: 'WARNING',
            text: translate('warnings.intervention.interventionOnGoing'),
            buttonCancel: translate('button.cancel'),
            onConfirm: () => saveAndStartIntervention(intervention)
          });
        }
      });
  }, [hashId, intervention, open, saveAndStartIntervention, history, storedInterventionForm, outOfSyncStatus]);

  const reassignOperator = useCallback((operator) => {
    InterventionsService.reassignOperator(hashId, operator)
      .then(() => {
        enqueueSnackbar(translate('confirms.intervention.operatorReassigned'), { variant: 'success' });
        // If the intervention is stored on the device, remove it
        InterventionHelper.deleteInterventionStored(hashId);
        // Reload the intervention
        setIsLoading(true);
        InterventionsService.getIntervention(hashId)
          .then((interventionDetailFromServer) => {
            setIntervention(interventionDetailFromServer);
          })
          .catch(() => enqueueSnackbar(translate('errors.noIntervention'), { variant: 'error' }))
          .finally(() => setIsLoading(false));
      })
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [enqueueSnackbar, hashId]);

  const handleReassignOperator = useCallback(() => {
    open({
      type: 'REASSIGN_OPERATOR',
      operator: intervention.operator,
      interventionStatus: intervention.status,
      onConfirm: operator => reassignOperator(operator)
    });
  }, [open, intervention, reassignOperator]);

  const displayInterventionFormButton = useCallback(() => {
    // If the intervention cannot be edited, no actions on the form can be performed
    if (!userStore.isViewingInstitution() || !intervention.canEdit || !INTERVENTION_STATUS) {
      return null;
    }

    // If the intervention is already published and to validate, allow verify
    if (intervention.status === INTERVENTION_STATUS.TO_VALIDATE.key) {
      return (
        <ButtonBlue
          margin="2rem 0"
          onClick={() => {
            history.push({
              pathname: `/interventions/${hashId}/inspection`,
              state: {
                needToBeStored: false,
                currentStep: 5,
                intervention
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
          {translate('button.updateValidateIntervention')}
        </ButtonBlue>
      );
    }

    // If the intervention is already published, allow modifications without downloading
    if (intervention.status === INTERVENTION_STATUS.TO_SIGN.key
      || intervention.status === INTERVENTION_STATUS.FINISHED.key) {
      return (
        <ButtonBlue
          margin="2rem 0"
          onClick={() => open({
            type: 'WARNING',
            text: translate('warnings.intervention.modifyFinishedIntervention'),
            buttonConfirm: translate('button.confirm'),
            buttonCancel: translate('button.cancel'),
            onConfirm: () => {
              history.push({
                pathname: `/interventions/${hashId}/inspection`,
                state: {
                  needToBeStored: false,
                  currentStep: intervention.status === INTERVENTION_STATUS.TO_SIGN.key ? 5 : 0,
                  intervention
                }
              });
            }
          })}
        >
          <FontAwesomeIcon icon={faEdit} />
          {translate('button.updateIntervention')}
        </ButtonBlue>
      );
    }

    // If the intervention is ongoing AND waitingToSubmit (has been submitted offline and is waiting for network to be really submitted)
    // disable the continue button and display a tooltip
    if (intervention.status === INTERVENTION_STATUS.ONGOING.key
      && intervention.isWaitingToSubmit) {
      return (
        <Tooltip title={translate('pageInterventionDetail.tooltipWaitingToSubmit')}>
          <span>
            <ButtonBlue
              data-cy="continueIntervention"
              disabled
              margin="2rem 0"
              onClick={handleContinueIntervention}
            >
              <FontAwesomeIcon icon={faPlay} />
              {translate('button.continueIntervention')}
            </ButtonBlue>
          </span>
        </Tooltip>
      );
    }

    // If the intervention is ongoing, allow the user to continue and download (if not yet downloaded)
    if (intervention.status === INTERVENTION_STATUS.ONGOING.key) {
      return (
        <>
          {!storedInterventionForm && (
            <span>
              <ButtonBlueLight disabled={userStore.isOffline} margin="1rem 0" onClick={handleDownloadIntervention}>
                <FontAwesomeIcon icon={faDownload} />
                {translate('button.downloadIntervention')}
              </ButtonBlueLight>
            </span>
          )}
          <span>
            <ButtonBlue
              data-cy="continueIntervention"
              disabled={!intervention.canEdit}
              margin="2rem 0"
              onClick={handleContinueIntervention}
            >
              <FontAwesomeIcon icon={faPlay} />
              {translate('button.continueIntervention')}
            </ButtonBlue>
          </span>
        </>
      );
    }

    // If the intervention hasn't been started, allow the user to start and download (if not yet downloaded)
    if (intervention.status === INTERVENTION_STATUS.PENDING.key || intervention.status === INTERVENTION_STATUS.READY.key) {
      return (
        <>
          {!storedInterventionForm && (
            <span>
              <ButtonBlueLight disabled={userStore.isOffline} margin="1rem 0" onClick={handleDownloadIntervention}>
                <FontAwesomeIcon icon={faDownload} />
                {translate('button.downloadIntervention')}
              </ButtonBlueLight>
            </span>
          )}
          <span>
            <ButtonBlue disabled={isStarting} margin="2rem 0" onClick={handleStartIntervention}>
              <FontAwesomeIcon icon={isStarting ? faSpinner : faPlay} spin={isStarting} />
              {translate('button.startIntervention')}
            </ButtonBlue>
          </span>
        </>
      );
    }

    // Other cases shouldn't happen, display nothing by default
    return null;
  }, [intervention, storedInterventionForm, isStarting, INTERVENTION_STATUS, open, userStore,
    handleContinueIntervention, handleDownloadIntervention, handleStartIntervention, hashId, history]);

  if (isLoading) {
    return <SkeletonDetailIntervention />;
  }

  if (!isLoading && !intervention) {
    return (
      <FlexContainer alignItems="center" flexDirection="column" justifyContent="center">
        <TextError>{translate('errors.noIntervention')}</TextError>
        <Link to={ROUTES.INTERVENTION.LIST}>
          <ButtonBlueLight>
            {translate('button.backInterventionList')}
          </ButtonBlueLight>
        </Link>
      </FlexContainer>
    );
  }

  return (
    <>
      <Wrapper data-tour="step-detail-header">
        <TitlePage>
          {translate('pageInterventionDetail.title')}
        </TitlePage>

        {!userStore.isOffline && (
          <Grid alignItems="center" container justifyContent="center" spacing={2} style={{ marginBottom: 20 }}>
            {intervention.canPlan && (
              <Grid item>
                <Link
                  to={{
                    pathname: `/interventions/${hashId}/plan`,
                    state: {
                      isNew: false
                    }
                  }}
                >
                  <ButtonBlueLight>
                    <FontAwesomeIcon icon={faClock} />
                    {translate('button.replanIntervention')}
                  </ButtonBlueLight>
                </Link>
              </Grid>
            )}

            {intervention.canReassign && (
              <Grid item>
                <ButtonBlueLight onClick={handleReassignOperator}>
                  <FontAwesomeIcon icon={faUserTie} />
                  {translate('button.updateAssignedOperator')}
                </ButtonBlueLight>
              </Grid>
            )}
          </Grid>
        )}

        {intervention.form && intervention.form.equipmentOwnerField && (
          <InterventionListItemDetails
            intervention={FormHelper.BACK_LIST_MAPPER(intervention)}
            INTERVENTION_STATUS={INTERVENTION_STATUS}
            maxwidth="500px"
            operatorFullName={intervention.operator?.fullName}
          />
        )}

        <FlexContainer alignItems="center" flexDirection="column">
          {displayInterventionFormButton()}

          {(intervention.status === INTERVENTION_STATUS.TO_SIGN.key || intervention.status === INTERVENTION_STATUS.FINISHED.key) && (
            <ButtonBlue
              margin="2rem 0"
              onClick={() => InterventionsService.getInterventionCerfa(intervention.hashId).then((response) => {
                response && DocumentHelper.displayPDF({
                  displayModal: modalType => open({
                    type: modalType,
                    title: response.name,
                    pdf: {
                      name: response.name,
                      base64Content: DocumentHelper.getPDFWithBase64(response)
                    }
                  })
                });
              })}
            >
              <FontAwesomeIcon icon={faFilePdf} />
              {translate('button.displayCerfa')}
            </ButtonBlue>
          )}
        </FlexContainer>
      </Wrapper>

      <InterventionDetailBody>
        <div data-tour="step-detail-body">
          {intervention.comment && (
            <Wrapper>
              <FlexContainer alignItems="center" flexDirection="column">
                <Text as="h3" margin="2rem 0 0 0">{`${translate('pageInterventionDetail.planificationComment')} :`}</Text>
                <TextMultiline>{intervention.comment}</TextMultiline>
              </FlexContainer>
            </Wrapper>
          )}

          {!userStore.isOffline
            && getAddressFromIntervention(intervention)
            && <InterventionMap address={getAddressFromIntervention(intervention, true)} />}

          {intervention.customerField && intervention.customerField.entity && intervention.customerField.entity.phoneNumber && (
            <Wrapper>
              <FlexContainer justifyContent="center">
                <a href={`tel:${intervention.customerField.entity.phoneNumber}`}>
                  <ButtonBlueLight>
                    <FontAwesomeIcon icon={faPhone} />
                    {translate('button.callCustomer')}
                  </ButtonBlueLight>
                </a>
              </FlexContainer>
            </Wrapper>
          )}
        </div>

        {intervention.form && (
          <div data-tour="step-detail-rest">
            <Wrapper>
              <Grid container spacing={4}>
                {intervention.form.equipmentOwnerField?.entity?.lastName && (
                  <Grid item md={4} xs={12}>
                    <InterventionDetailCard>
                      <h2>
                        <FontAwesomeIcon icon={faUser} />
                        {translate('common.owner')}
                      </h2>
                      <p>{`${intervention.form.equipmentOwnerField.entity.firstName} ${intervention.form.equipmentOwnerField.entity.lastName || ''}`}</p>
                      {intervention.form.equipmentOwnerField.entity.institutionIdentifier && (
                        <p>
                          <FontAwesomeIcon icon={faBuilding} />
                          {intervention.form.equipmentOwnerField.entity.institutionIdentifier}
                        </p>
                      )}
                      {getAddressFromIntervention(intervention) && (
                        <TextAddress address={getAddressFromIntervention(intervention)} withIcon />
                      )}
                    </InterventionDetailCard>
                  </Grid>
                )}

                {intervention.form.equipmentField && intervention.form.equipmentField.equipment && (
                  <Grid item md={4} xs={12}>
                    <InterventionDetailCard>
                      <h2>
                        <FontAwesomeIcon icon={faAirConditioner} />
                        {translate('common.equipment')}
                      </h2>
                      <p>
                        <FontAwesomeIcon icon={faTag} />
                        {getIdentifier(intervention.form.equipmentField.equipment)}
                      </p>
                      {intervention.form.equipmentField.circuit?.fluid && (
                        <p>
                          <FontAwesomeIcon icon={faTint} />
                          {`${intervention.form.equipmentField.circuit.fluid.name} - ${intervention.form.equipmentField.circuit.fluidCapacity}kg`}
                        </p>
                      )}
                    </InterventionDetailCard>
                  </Grid>
                )}

                {intervention.form.leakDetectorField && intervention.form.leakDetectorField.tool && (
                  <Grid item md={4} xs={12}>
                    <InterventionDetailCard>
                      <h2>
                        <FontIcon icon="icon-leak-detector" />
                        {translate('common.leakDetector')}
                      </h2>
                      <p>
                        <FontAwesomeIcon icon={faTag} />
                        {getIdentifier(intervention.form.leakDetectorField.tool)}
                      </p>
                      {intervention.form.leakDetectorField.tool.lastControlDate && (
                        <p>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          {formatDateString(intervention.form.leakDetectorField.tool.lastControlDate)}
                        </p>
                      )}
                    </InterventionDetailCard>
                  </Grid>
                )}

                {intervention.form.scaleField && intervention.form.scaleField.tool && (
                  <Grid item md={4} xs={12}>
                    <InterventionDetailCard>
                      <h2>
                        <FontAwesomeIcon icon={faBalanceScale} />
                        {translate('common.scale')}
                      </h2>
                      <p>
                        <FontAwesomeIcon icon={faTag} />
                        {getIdentifier(intervention.form.scaleField.tool)}
                      </p>
                      {intervention.form.scaleField.tool.lastControlDate && (
                        <p>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          {formatDateString(intervention.form.scaleField.tool.lastControlDate)}
                        </p>
                      )}
                    </InterventionDetailCard>
                  </Grid>
                )}
              </Grid>
            </Wrapper>

            <>
              {intervention.form.interventionCategory && Object.values(intervention.form.interventionCategory)
                .filter(type => (typeof type !== 'string'))
                .some(type => type) && (
                  <InterventionDetailType>
                    <SubtitlePage>{translate('pageInterventionDetail.interventionType')}</SubtitlePage>
                    <InterventionType displayLabel types={intervention.form.interventionCategory} />
                  </InterventionDetailType>
                )}
            </>

            <Wrapper>
              {intervention.form.leakField && intervention.form.leakField.leaks.length > 0 && (
                <>
                  <SubtitlePage>{translate('common.leaks')}</SubtitlePage>
                  <GenericTable<LeakEntryView>
                    headers={[
                      {
                        name: 'leakRepaired',
                        label: translate('common.repaired'),
                        template: row => (
                          <TableCell key={shortid.generate()}>
                            <FontIcon
                              color={row.leakRepaired ? 'var(--green)' : 'var(--red)'}
                              fontSize="4rem"
                              icon="icon-hazard"
                            />
                          </TableCell>
                        )
                      }, {
                        name: 'location',
                        label: translate('common.location'),
                        template: row => (
                          <TableCell key={shortid.generate()}>
                            {row.location}
                          </TableCell>
                        )
                      }, {
                        name: 'documents',
                        label: translate('common.documents'),
                        template: row => (
                          <TableCell key={shortid.generate()}>
                            <FlexContainer alignItems="center">
                              {row.documents.map(picture => (
                                <img
                                  alt={picture.name}
                                  key={shortid.generate()}
                                  src={DocumentHelper.getImageWithBase64(picture)}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => open({
                                    type: 'DISPLAY_PICTURE',
                                    name: picture.name,
                                    base64Content: DocumentHelper.getImageWithBase64(picture)
                                  })}
                                  onKeyDown={() => open({
                                    type: 'DISPLAY_PICTURE',
                                    name: picture.name,
                                    base64Content: DocumentHelper.getImageWithBase64(picture)
                                  })}
                                />
                              ))}
                            </FlexContainer>
                          </TableCell>
                        )
                      }
                    ]}
                    rows={intervention.form.leakField.leaks}
                  />
                </>
              )}

              {intervention.form.fluidManipulationField && intervention.form.fluidManipulationField.fluidManipulations.length > 0 && (
                <>
                  <SubtitlePage>Manipulations</SubtitlePage>
                  <GenericTable<FluidManipulationEntryView>
                    headers={[
                      {
                        name: 'manipulationType',
                        label: translate('common.type'),
                        template: row => (
                          <TableCell key={shortid.generate()}>
                            <Translate text={`manipulations.${row.manipulationType.key}`} />
                          </TableCell>
                        )
                      }, {
                        name: 'fluid',
                        label: translate('common.fluid'),
                        template: row => (
                          <TableCell key={shortid.generate()}>
                            {`${row.fluid.label} - ${row.fluidAmount}kg`}
                          </TableCell>
                        )
                      }, {
                        name: 'fluidBottle',
                        label: translate('common.bottle'),
                        template: row => (
                          <TableCell key={shortid.generate()}>
                            {BottleHelper.getTranslatedType({
                              type: row.fluidBottle.bottleType.key,
                              isNewFluid: row.fluidBottle.isNewFluid,
                              identifier: row.fluidBottle.identifier
                            })}
                          </TableCell>
                        )
                      }
                    ]}
                    rows={intervention.form.fluidManipulationField.fluidManipulations}
                  />
                </>
              )}
            </Wrapper>
          </div>
        )}
      </InterventionDetailBody>
    </>
  );
});
