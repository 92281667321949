import { SelectItem } from 'utils/types';
import { BottleView } from './BottleView';

export class FluidMovementView {
  hashId: string = '';
  movementDate?: string;
  bottle?: BottleView;
  fluid?: SelectItem;
  isNewFluid?: boolean = true;
  isRetrievalBottle?: boolean = false;
  boughtInFrance?: boolean = true;
  theoricalAmount?: number;
  measuredAmount?: number;
  supplierOrRecipient?: SelectItem;
  transporter?: any;
  transporterImat?: string;
  movementType?: SelectItem;
  signature?: any;
  maximumCapacity?: number;
  volume?: number;
  comment?: string;
  linkedDocument?: any;
  bsffIdentifier?: string;
  lastControlDate?: string;
  forceCreate: boolean = false;
  canEdit?: boolean = false;
  canDelete?: boolean = false;
  bottles: BottleView[] = [];
  ignoreTd: boolean = false;
}
