import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Checkbox, FormControlLabel, FormGroup, TextField
} from '@material-ui/core';

import {
  CustomTooltip, FlexContainer, RequiredSelectHack, Text
} from 'components';

import {
  Args, FormState, InputInitializer, StateErrors
} from 'react-use-form-state';
import { COUNTRY_CODE } from 'utils/constants';
import { translate } from 'utils/helpers';
import { Question } from '../Question/Question';

const CheckboxContainer = styled(FlexContainer).attrs({
  alignItems: 'center'
})`
  @media (min-width: 561px) {
    label {
      min-width: 440px;
    }
  }

  @media (max-width: 560px) {
    label {
      flex: 1
    }
  }
`;

const EquipementObservations = styled.textarea`
  min-height: 80px;
  max-width: 100%;
  min-width: 100%;
  padding: 1rem;
`;

interface FormStateCategoryView {
  assembly?: boolean;
  commissioning?: boolean;
  dismantling?: boolean;
  maintenance?: boolean;
  modification?: boolean;
  nonPeriodicControl?: boolean;
  observations?:string;
  other?: string;
  periodicControl?: boolean;
  // eslint-disable-next-line camelcase
  ui_other?: boolean;
}

export const InterventionType = ({
  checkbox,
  countryCode,
  formState,
  interventionTypeValues,
  text,
  textarea
} : {
  checkbox,
  countryCode: string,
  formState: FormState<FormStateCategoryView, StateErrors<FormStateCategoryView, string>>,
  interventionTypeValues,
  text: InputInitializer<FormStateCategoryView, Args<keyof FormStateCategoryView, void>, any>,
  textarea: InputInitializer<FormStateCategoryView, Args<keyof FormStateCategoryView, void>, any>
}) => {
  const isFrance = countryCode === COUNTRY_CODE.FR;

  const forceControl = isFrance && (
    formState.values.assembly
    || formState.values.commissioning
    || formState.values.modification
    || formState.values.maintenance
  );

  // If a base type is selected, automatically add a periodic control if needed
  forceControl && !formState.values.nonPeriodicControl && !formState.values.periodicControl && formState.setField('nonPeriodicControl', true);
  (formState.values.other == null) && formState.setField('other', '');

  return (
    <Fragment>
      <Question label={translate('interventionForm.interventionType.questionType')}>
        <RequiredSelectHack value={interventionTypeValues.some(type => type === true) ? 'valid' : ''} width="50%" />
        <FormGroup>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="assembly" />}
              label={translate('interventionTypes.assembly')}
              {...checkbox('assembly')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.assemblyTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="commissioning" />}
              label={translate('interventionTypes.commissioning')}
              {...checkbox('commissioning')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.commissioningTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="modification" />}
              label={translate('interventionTypes.modification')}
              {...checkbox('modification')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.modificationTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="maintenance" />}
              label={translate('interventionTypes.maintenance')}
              {...checkbox('maintenance')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.maintenanceTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="periodicControl"
                onChange={(e) => {
                  // When deselecting a periodic control while a control is needed, select the non periodic control
                  forceControl && formState.values.periodicControl && formState.setField('nonPeriodicControl', true);
                  // When selecting a periodic control, deselect the non periodic control
                  !formState.values.periodicControl && formState.setField('nonPeriodicControl', false);
                  checkbox('periodicControl').onChange(e);
                }}
              />}
              label={translate('interventionTypes.periodicControl')}
              {...checkbox('periodicControl')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.periodicControlTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <Fragment>
              <FormControlLabel
                control={<Checkbox value="nonPeriodicControl"
                  onChange={(e) => {
                    // When deselecting a non periodic control while a control is needed, select the periodic control
                    forceControl && formState.values.nonPeriodicControl && formState.setField('periodicControl', true);
                    // When selecting a non periodic control, deselect the periodic control
                    !formState.values.nonPeriodicControl && formState.setField('periodicControl', false);
                    checkbox('nonPeriodicControl').onChange(e);
                  }}
                />}
                label={translate('interventionTypes.nonPeriodicControl')}
                {...checkbox('nonPeriodicControl')}
              />
            </Fragment>
            <CustomTooltip text={translate('interventionForm.interventionType.nonPeriodicControlTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="dismantling" />}
              label={translate('interventionTypes.dismantling')}
              {...checkbox('dismantling')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.dismantlingTooltip')} />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={<Checkbox value="ui_other"
                onChange={(e) => {
                  // When deselecting the 'other' type, remove the associated text
                  formState.values.ui_other && formState.setField('other', '');
                  checkbox('ui_other').onChange(e);
                }}
              />}
              label={translate('interventionTypes.other')}
              {...checkbox('ui_other')}
            />
            <CustomTooltip text={translate('interventionForm.interventionType.otherTooltip')} />
          </CheckboxContainer>
          {formState.values.ui_other && (
            <TextField
              label={translate('interventionTypes.other')}
              name="other"
              required
              {...text('other')}
            />
          )}
        </FormGroup>
      </Question>

      <Text margin="2rem 0 0.5rem 0">{`${translate('interventionForm.interventionType.observationType')} :`}</Text>
      <EquipementObservations {...textarea('observations')} placeholder={translate('interventionForm.interventionType.observationType')} />
    </Fragment>
  );
};
