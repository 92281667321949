/* eslint-disable padded-blocks */
import {
  faCheck,
  faFileExcel,
  faFilePdf,
  faInfoCircle,
  faSpinner,
  faSync,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, ListItemText, TableCell } from '@material-ui/core';
import {
  ButtonBlueLight,
  ButtonText,
  CustomTooltip,
  FlexContainer,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  SkeletonTableSmall,
  SubtitlePage,
  TextError
} from 'components';

import { useEffectAfterRender, useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { BottleSummaryItem } from 'models';
import { BottleFilter } from 'models/request/list/BottleFilter';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import Media from 'react-media';
import { useHistory, useLocation } from 'react-router-dom';
import { FluidsService } from 'services';
import shortid from 'shortid';
import { DISTRIBUTOR_ROLES } from 'utils/constants';
import {
  BottleHelper,
  DocumentHelper,
  FilterHelper,
  formatDateString,
  translate,
  UserHelper
} from 'utils/helpers';
import { HeaderType } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import { BottleListFilters } from './BottleListFilters';

const getListHeaders: (
  displayBsd: (row: BottleSummaryItem) => void,
  isDistributor: boolean
) => HeaderType<BottleSummaryItem>[] = (displayBsd, isDistributor) => ([
  {
    name: 'bottleType',
    label: translate('common.type'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        {BottleHelper.getTranslatedType({
          type: row.bottleType,
          isNewFluid: row.isNewFluid,
          identifier: row.identifier
        })}
        {row.hasWarning && (
          <CustomTooltip
            icon={faInfoCircle}
            size="xs"
            text={translate('warnings.bottle.modifiedCanHaveLeak')}
          />
        )}
      </TableCell>
    )
  }, {
    name: 'fluidLabel',
    label: translate('common.fluid'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        <ListItemText
          primary={row.fluidLabel}
          secondary={`${row.fluidAmount || 0}kg${row.maximumCapacity ? ` / ${row.maximumCapacity}kg` : ''}`}
        />
      </TableCell>
    )
  }, {
    name: 'bsd',
    label: translate('common.followUpDocument'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        {row.bottleType === 'RETRIEVAL_BOTTLE' && row.retrievalDate && (row.hasInterventions || row.bsffIdentifier) && (
          <ButtonText
            color="var(--blue)"
            underlineHover
            onClick={(e) => {
              e.stopPropagation();
              displayBsd(row);
            }}
          >
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '0.5rem' }} />
            {translate('common.BSD')}
          </ButtonText>
        )}
      </TableCell>
    )
  }, {
    name: 'receptionDate',
    label: translate('pageBottleList.columnReceptionDate'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        <ListItemText
          primary={`${formatDateString(row.receptionDate)} / ${formatDateString(row.retrievalDate)}`}
        />
      </TableCell>
    )
  }, {
    name: 'lastControlDate',
    label: translate('common.lastControlDate'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        {formatDateString(row.lastControlDate || row.receptionDate)}
      </TableCell>
    )
  },
  ...(!isDistributor ? [{
    name: 'retrievalConfirmed',
    label: translate('pageBottleList.columnRetrievalConfirmed'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        {(row.retrievalDate && row.bsffIdentifier)
          ? (
            <FontAwesomeIcon
              color={row.retrievalConfirmed ? 'var(--green)' : 'var(--red)'}
              icon={row.retrievalConfirmed ? faCheck : faTimes}
              size="lg"
            />
          )
          : '-'
        }
      </TableCell>
    )
  }] : []),
  {
    name: 'validated',
    label: translate('interventionStatus.VALIDATED'),
    template: (row: BottleSummaryItem) => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon
          color={row.validated ? 'var(--green)' : 'var(--red)'}
          icon={row.validated ? faCheck : faTimes}
          size="lg"
        />
      </TableCell>
    )
  }
]);

const EXPORT_MAIL_SIZE = 1500;

export const BottleList = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { open } = useModal();

  const filterKey = 'bottleList';
  const locationState: any = location.state;
  const historyFilter: FilterItem<BottleFilter>[] = (locationState && locationState.filters);
  const defaultFilters: FilterItem<BottleFilter>[] = [{
    key: 'isRetrieved',
    label: translate('common.inStock'),
    booleanValue: false
  }];

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [bottleList, setBottleList] = useState<BottleSummaryItem[]>([]);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(historyFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [listSize, setListSize] = useState(0);
  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);

  const getBottleList = useCallback(({ pageChange = false } = {}) => {
    setIsLoading(true);
    FluidsService.getBottleList(FilterHelper.buildFilterForm(filters, search), currentPage)
      .then((response) => {
        if (response) {
          let allBottles = response.content;
          if (pageChange && currentPage > 1) {
            allBottles = [].concat(...bottleList, response.content);
          }
          setBottleList(allBottles);
          setMaxPage(response.totalPages);
          setListSize(response.totalElements);
        }
      }).finally(() => setIsLoading(false));
  }, [currentPage, bottleList, filters, search]);

  const reloadList = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 1) getBottleList({ pageChange: false });
    else setCurrentPage(1);
  };

  useEffectAfterRender(() => {
    reloadList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getBottleList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  const displayPDFModal = useCallback(response => DocumentHelper.displayPDF({
    displayModal: modalType => open({
      type: modalType,
      title: response.name,
      pdf: {
        name: response.name,
        base64Content: DocumentHelper.getPDFWithBase64(response)
      }
    })
  }), [open]);

  const displayBsd = useCallback(row => FluidsService.getBsd(row.id)
    .then(bsd => displayPDFModal(bsd)), [displayPDFModal]);

  const loadMore = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setCurrentPage(currentPage + 1);
  }, [isLoading, currentPage]);

  const handleRowClick = useCallback((row: BottleSummaryItem) => history.push(`/bouteilles/${row.id}`), [history]);

  const BottleListTable = ({ listHeadersTable }) => (
    <GenericTable<BottleSummaryItem>
      dataCy="bottleList"
      hasMore={currentPage < maxPage}
      headers={listHeadersTable}
      loadMore={loadMore}
      rows={bottleList}
      total={listSize}
      onRowClick={handleRowClick}
    />
  );

  const renderFilters = useCallback(({
    currentFilters,
    setCurrentFilters
  }: {
    currentFilters: FilterItem<BottleFilter>[],
    setCurrentFilters: (filters: FilterItem<BottleFilter>[]) => void
  }) => (
    <BottleListFilters currentFilters={currentFilters} setCurrentFilters={setCurrentFilters} />
  ), []);

  const exportBottles = useCallback(() => {
    setIsExporting(true);

    if (Number(listSize) > EXPORT_MAIL_SIZE) {
      const timeout = new Promise(() => {
        setTimeout(() => 'ok', 1000);
      });
      const controller = new AbortController();
      const { signal } = controller;
      Promise.race([FluidsService.exportBottlesByEmail(FilterHelper.buildFilterForm(filters, search), signal), timeout])
        .then(() => {
          enqueueSnackbar(
            translate('confirms.bottle.exportByEmail').replace('<br />', '\n'),
            { variant: 'success', autoHideDuration: 5000 }
          );
          controller.abort();
        })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => {
          setIsExporting(false);
        });
    } else {
      FluidsService.exportBottles(FilterHelper.buildFilterForm(filters, search)).then((response) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(response);
        downloadLink.download = response.name;
        downloadLink.click();
      })
        .catch(error => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
      ))
        .finally(() => setIsExporting(false));
    }
  }, [listSize, enqueueSnackbar, filters, search]);

  const renderListHeader = useCallback(() => (
    <div className="buttonsContainer">
      <ButtonBlueLight disabled={isExporting} onClick={() => exportBottles()}>
        <FontAwesomeIcon icon={isExporting ? faSpinner : faFileExcel} spin={isExporting} />
        {translate('button.export')}
      </ButtonBlueLight>
    </div>
  ), [exportBottles, isExporting]);

  return (
    <GenericListContainer>
      <SubtitlePage>
        {translate('pageBottleList.title')}
        <IconButton className="ml1" onClick={reloadList}>
          <FontAwesomeIcon color="var(--blue)" icon={faSync} spin={isLoading} />
        </IconButton>
        <Media
          query="(min-width: 769px)"
          render={renderListHeader}
        />
      </SubtitlePage>
      <GenericListFilters
        ComponentFilter={renderFilters}
        defaultFilters={defaultFilters}
        disabled={false}
        filterKey={filterKey}
        filters={filters}
        isVisible
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
        withSidePanel
      />

      <div data-tour="step-admin-bottleList">
        {!isLoading && bottleList && bottleList.length === 0
          ? (
            <FlexContainer alignItems="center" flexDirection="column">
              <TextError>{translate('errors.noBottle')}</TextError>
            </FlexContainer>
          ) : (
            <Media query="(max-width: 768px)">
              {matches => (matches
                ? <BottleListTable listHeadersTable={[...getListHeaders(displayBsd, isDistributor)].splice(0, 3)} />
                : <BottleListTable listHeadersTable={getListHeaders(displayBsd, isDistributor)} />
              )}
            </Media>
          )
        }
      </div>

      {isLoading && <SkeletonTableSmall />}
    </GenericListContainer>
  );
});
