import { SelectItem } from 'utils/types';

export class BottleView {
  bottleType?: SelectItem;
  boughtInFrance: boolean = true;
  bsffIdentifier?: string;
  canDelete: boolean = false;
  canEdit: boolean = false;
  comment?: string;
  fluid?: SelectItem;
  hasInterventions: boolean = false;
  hasWarning: boolean = false;
  id: string = '';
  identifier: string = '';
  initialAmount?: number;
  isNewFluid: boolean = true;
  lastControlDate?: string;
  maximumCapacity?: number;
  measuredAmount?: number;
  receptionDate?: string;
  retrievalConfirmed: boolean = false;
  retrievalDate?: string;
  theoricalAmount?: number;
  validated?: boolean = true;
  volume?: number;

  // Front specific fields
  stringType?: 'NEW_BOTTLE' | 'RETRIEVAL_BOTTLE' | 'TRANSFER_BOTTLE' = 'NEW_BOTTLE';
}
