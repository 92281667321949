import {
  faEdit, faFilePdf, faSync, faTintSlash
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import {
  ButtonText,
  FlexContainer,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  SkeletonTableSmall,
  SubtitlePage,
  TextError
} from 'components';
import { useEffectAfterRender, useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { FluidMovementSummaryItem } from 'models';
import { useSnackbar } from 'notistack';

import React, { useCallback, useEffect, useState } from 'react';
import Media from 'react-media';
import { FluidMovementsService } from 'services';
import shortid from 'shortid';
import {
  DocumentHelper, FilterHelper, formatDate, translate
} from 'utils/helpers';

import { PendingMovementsListFilters } from './PendingMovementsListFilters';

const getFluidMovementListHeaderDate = () => ({
  name: 'date',
  label: translate('common.date'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {row.movementDate ? formatDate(row.movementDate) : '-'}
    </TableCell>
  )
});

const displayFluid = (row) => {
  if (row.fluid && row.fluid.label) {
    return row.fluid.label;
  }
  return '-';
};

const getFluidMovementListHeaderFluid = () => ({
  name: 'fluid',
  label: translate('common.fluid'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {displayFluid(row)}
      {' '}
      {row.movementType === 'INPUT' && row.isNewFluid && `(${translate('fluids.newFluid')})`}
      {row.movementType === 'INPUT' && !row.isNewFluid && `(${translate('fluids.regeneratedFluid')})`}
    </TableCell>
  )
});

const getFluidMovementListHeaderBottle = () => ({
  name: 'bottle',
  label: translate('common.bottle'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {row.bottle ? row.bottle.label : '-'}
    </TableCell>
  )
});

const getFluidMovementListHeaderQuantity = () => ({
  name: 'quantity',
  label: translate('common.quantity'),
  template: row => (
    row.movementType && (
      <TableCell key={shortid.generate()}>
        <span>{`${row.theoricalAmount || 0}kg`}</span>
      </TableCell>
    )
  )
});

const getFluidMovementListHeaderBsd = getBsd => ({
  name: 'bsd',
  label: translate('common.followUpDocument'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {row.bottle && row.bsffIdentifier && (
        <ButtonText
          color="var(--blue)"
          underlineHover
          onClick={(e) => {
            e.stopPropagation();
            getBsd(row);
          }}
        >
          <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '0.5rem' }} />
          {translate('button.consultBSFF')}
        </ButtonText>
      )}
    </TableCell>
  )
});

const getFluidMovementListHeaderActions = editAction => ({
  name: 'actions',
  label: translate('common.actions'),
  template: row => (
    <TableCell key={shortid.generate()}>
      <Grid container wrap="nowrap">
        <Grid item>
          <IconButton onClick={() => editAction(row)}>
            <FontAwesomeIcon color="var(--blue)" icon={faEdit} size="sm" />
          </IconButton>
        </Grid>
      </Grid>
    </TableCell>
  )
});

const getFluidMovementsListHeadersDesktop = (getBsd, editAction) => ([
  getFluidMovementListHeaderDate(),
  getFluidMovementListHeaderFluid(),
  getFluidMovementListHeaderBottle(),
  getFluidMovementListHeaderQuantity(),
  getFluidMovementListHeaderBsd(getBsd),
  getFluidMovementListHeaderActions(editAction)
]);

const fluidMovementsListHeadersMobile = editAction => [
  getFluidMovementListHeaderFluid(),
  getFluidMovementListHeaderQuantity(),
  getFluidMovementListHeaderActions(editAction)
];

export const PendingMovementsList = observer(() => {
  const { open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const filterKey = 'pendingMovementList';
  const [isLoading, setIsLoading] = useState(false);
  const [fluidMovementsList, setFluidMovementsList] = useState([]);
  const [fluidMovementsListSize, setFluidMovementsListSize] = useState(0);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(FilterHelper.getFilters(filterKey) || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const getFluidMovementList = useCallback(({ pageChange = false } = {}) => {
    setIsLoading(true);
    FluidMovementsService.getPendingMovementsList(FilterHelper.buildFilterForm(filters, search), currentPage)
      .then((response) => {
        if (response) {
          let allMovements = response.content;
          if (pageChange && currentPage > 1) {
            allMovements = [].concat(...fluidMovementsList, response.content);
          }
          setFluidMovementsList(allMovements);
          setMaxPage(response.totalPages);
          setFluidMovementsListSize(response.totalElements);
        }
      }).finally(() => setIsLoading(false));
  }, [filters, search, currentPage, fluidMovementsList]);

  const reloadList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 1) getFluidMovementList({ pageChange: false });
    else setCurrentPage(1);
  }, [currentPage, getFluidMovementList]);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getFluidMovementList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  const displayPDFModal = response => DocumentHelper.displayPDF({
    displayModal: modalType => open({
      type: modalType,
      title: response.name,
      pdf: {
        name: response.name,
        base64Content: DocumentHelper.getPDFWithBase64(response)
      }
    })
  });

  const getBsd = row => FluidMovementsService.getBsd(row.hashId)
    .then(bsff => displayPDFModal(bsff));

  const validatePendingMovement = useCallback((form) => {
    FluidMovementsService.validatePendingMovement(form.hashId, form)
      .then(() => {
        enqueueSnackbar(translate('confirms.movement.update'), { variant: 'success' });
        close();
      })
      .finally(() => reloadList());
  }, [reloadList, enqueueSnackbar, close]);

  const editAction = useCallback(row => open({
    type: 'CHECK_PENDING_MOVEMENT',
    onConfirm: validatePendingMovement,
    defaultValues: row
  }), [validatePendingMovement, open]);

  const loadMore = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setCurrentPage(currentPage + 1);
  }, [isLoading, currentPage]);

  const FluidMovementListTable = ({ listHeaders }) => (
    <GenericTable<FluidMovementSummaryItem>
      dataCy="fluidMovementsList"
      dataTour="step-fluids-list"
      hasMore={currentPage < maxPage}
      headers={listHeaders}
      loadMore={loadMore}
      rows={fluidMovementsList}
      total={fluidMovementsListSize}
    />
  );

  return (
    <GenericListContainer>
      <SubtitlePage>
        {translate('pagePendingMovementList.title')}
        <IconButton className="ml1" onClick={reloadList}>
          <FontAwesomeIcon color="var(--blue)" icon={faSync} spin={isLoading} />
        </IconButton>
      </SubtitlePage>

      <GenericListFilters
        ComponentFilter={
          ({ currentFilters, setCurrentFilters }) => <PendingMovementsListFilters currentFilters={currentFilters} setCurrentFilters={setCurrentFilters} />
        }
        disabled={false}
        filterKey={filterKey}
        filters={filters}
        isVisible
        placeholder={translate('pageFluidMovementList.searchPlaceholder')}
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
        withSidePanel
      />

      {!isLoading && fluidMovementsList.length === 0
        ? (
          <FlexContainer alignItems="center" flexDirection="column">
            <FontAwesomeIcon color="var(--grey)" icon={faTintSlash} size="3x" />
            <TextError>{translate('errors.noPendingMovement')}</TextError>
          </FlexContainer>
        ) : (
          <Media query="(max-width: 768px)">
            {matches => (matches
              ? <FluidMovementListTable listHeaders={fluidMovementsListHeadersMobile(editAction)} />
              : <FluidMovementListTable listHeaders={getFluidMovementsListHeadersDesktop(getBsd, editAction)} />
            )}
          </Media>
        )}

      {isLoading && currentPage === 1 && <SkeletonTableSmall />}
    </GenericListContainer>
  );
});
