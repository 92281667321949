/* eslint-disable react/jsx-no-duplicate-props */
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';

import {
  Button, InputAdornment, TableCell, TextField
} from '@material-ui/core';
import Select from 'react-select';
import shortid from 'shortid';
import styled from 'styled-components';

import {
  ButtonBlue, FlexContainer, GenericListContainer,
  GenericTable, ReactTour, SkeletonTableSmall, SubtitlePage
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { BottleSummaryItem } from 'models';
import { useSnackbar } from 'notistack';
import sanitizeHtml from 'sanitize-html';
import { StocksService } from 'services';
import { BOTTLE_TYPES } from 'utils/constants';
import { BottleHelper, formatDateString, translate } from 'utils/helpers';
import { HeaderType } from 'utils/types';

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0;
  z-index: var(--zindexSmall);

  section > div {
    min-width: 250px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

const CustomQuantityTemplate = ({ row }: {row: BottleSummaryItem}) => {
  const [measuredAmount, setMesuredAmount] = useState(row.measuredAmount || '');

  const handleChange = (event) => {
    setMesuredAmount(event.target.value);
  };

  return (
    <TableCell data-id={row.id} data-measuredamount={measuredAmount}>
      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>
        }}
        inputProps={{
          step: '0.01',
          min: 0,
          type: 'number'
        }}
        label={translate('common.measuredQuantity')}
        style={{ minWidth: '140px' }}
        value={measuredAmount}
        onChange={handleChange}
      />
    </TableCell>
  );
};

const CustomCommentTemplate = ({ row }: {row: BottleSummaryItem}) => {
  const [comment, setComment] = useState(row.comment || '');

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <TableCell data-comment={comment} data-id={row.id}>
      <TextField
        label={translate('common.justification')}
        multiline
        value={comment}
        onChange={handleChange}
      />
    </TableCell>
  );
};

const getListHeadersTable: (
  handleTypeClick
) => HeaderType<BottleSummaryItem>[] = handleTypeClick => [
  {
    name: 'bottleType',
    label: translate('common.type'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {BottleHelper.getTranslatedType({ type: row.bottleType, isNewFluid: row.isNewFluid })}
      </TableCell>
    )
  }, {
    name: 'identifier',
    label: translate('common.identifier'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Button color="primary" variant="text" onClick={() => handleTypeClick(row)}>
          {row.identifier}
        </Button>
      </TableCell>
    )
  }, {
    name: 'fluidLabel',
    label: translate('common.fluid'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.fluidLabel}
      </TableCell>
    )
  }, {
    name: 'fluidAmount',
    label: translate('common.theoricalQuantity'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${row.fluidAmount} kg`}
      </TableCell>
    )
  }, {
    name: 'measuredAmount',
    label: translate('common.measuredQuantity'),
    template: row => <CustomQuantityTemplate key={shortid.generate()} row={row} />
  }, {
    name: 'comment',
    label: translate('common.justification'),
    template: row => <CustomCommentTemplate key={shortid.generate()} row={row} />
  }, {
    name: 'lastControlDate',
    label: translate('common.lastWeightDate'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {formatDateString(row.lastControlDate || row.receptionDate)}
      </TableCell>
    )
  }
];

export const ValidationList = observer(() => {
  const { open } = useModal();
  const { fluidStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [validationList, setValidationList] = useState([]);

  const [bottleTypeFilter, setBottleTypeFilter] = useState(null);
  const [bottleFluidFilter, setBottleFluidFilter] = useState(null);

  const getValidationList = () => {
    setIsLoading(true);
    StocksService.getValidationList()
      .then((response) => {
        if (response) {
          setValidationList(response);
        }
      }).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getValidationList();
  }, []);

  const controlFluid = () => {
    const allQuantityRow = document.querySelectorAll('[data-measuredamount]');
    const allCommentRow = document.querySelectorAll('[data-comment]');
    const finalValidationList = [];

    validationList.forEach((row: BottleSummaryItem) => {
      const fluidAmount: any = Array.from(allQuantityRow).filter((quantityRow: any) => quantityRow.dataset.id === row.id)[0];
      const comment: any = Array.from(allCommentRow).filter((commentRow: any) => commentRow.dataset.id === row.id)[0];

      finalValidationList.push({
        ...row,
        measuredAmount: fluidAmount && fluidAmount.dataset.measuredamount,
        comment: comment ? comment.dataset.comment : null
      });
    });

    StocksService.validateStock(finalValidationList).then(() => {
      enqueueSnackbar(translate('confirms.validation.update'), { variant: 'success' });
      getValidationList();
    }).catch(error => enqueueSnackbar(<span dangerouslySetInnerHTML={
      { __html: sanitizeHtml(error) }
    }/>, { variant: 'error' }));
  };

  const handleChangeBottleType = useCallback(value => setBottleTypeFilter(value), []);

  const handleChangeBottleFluid = useCallback(value => setBottleFluidFilter(value), []);

  const getFilteredList = useCallback(() => {
    if (!validationList) {
      return [];
    }
    return validationList
                .filter(bottle => (bottleFluidFilter ? bottleFluidFilter.label === bottle.fluidLabel : bottle))
                .filter(bottle => (bottleTypeFilter ? bottleTypeFilter.value === bottle.bottleType : bottle));
  }, [validationList, bottleFluidFilter, bottleTypeFilter]);

  const handleTypeClick = useCallback((row) => {
    open({
      type: 'BOTTLE_VARIATIONS',
      bottle: row
    });
  }, [open]);

  return (
    <>
      <GenericListContainer>
        <SubtitlePage>{translate('pageValidationList.title')}</SubtitlePage>

        <SelectContainer>
          <FlexContainer flexWrap="wrap">
            <Select
              id="selectBottleTypeFilter"
              isClearable
              noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
              options={BOTTLE_TYPES.map(type => ({
                value: type.key,
                label: BottleHelper.getTranslatedType({ type: type.key })
              }))}
              placeholder={translate('common.selectType')}
              value={bottleTypeFilter || ''}
              onChange={handleChangeBottleType}
            />
            <Select
              id="selectBottleFluidFilter"
              isClearable
              noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
              options={fluidStore.fluidOptions}
              placeholder={translate('common.selectFluid')}
              value={bottleFluidFilter || ''}
              onChange={handleChangeBottleFluid}
            />
          </FlexContainer>
          <div>
            <ButtonBlue disabled={getFilteredList().length === 0} onClick={controlFluid}>
              {translate('button.validateModifications')}
            </ButtonBlue>
          </div>
        </SelectContainer>

        {isLoading ? (
          <SkeletonTableSmall />
        ) : (
          <Fragment>
            <GenericTable<BottleSummaryItem>
              dataCy="bottleList"
              headers={getListHeadersTable(handleTypeClick)}
              rows={getFilteredList()}
            />
            <FlexContainer justifyContent="flex-end">
              {getFilteredList().length > 0 && (
                <ButtonBlue margin="2rem 0 0 0" onClick={controlFluid}>
                  {translate('button.validateModifications')}
                </ButtonBlue>
              )}
            </FlexContainer>
          </Fragment>
        )}
        <ReactTour steps={[]} />
      </GenericListContainer>
    </>
  );
});
