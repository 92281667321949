/* eslint-disable react/jsx-no-duplicate-props */
import { faTint } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, InputAdornment, TextField
} from '@material-ui/core';
import {
  CustomIcon,
  CustomTooltip,
  Datepicker,
  FlexContainer,
  FormLine,
  Question,
  RequiredSelectHack,
  TextNoOptionsAvailable
} from 'components';
import { SkeletonForm } from 'components/Skeletons';
import debounce from 'debounce-promise';
import { useFetch, useStores } from 'hooks';
import { FluidMovementView } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useFormState } from 'react-use-form-state';
import { FluidMovementsService, LegalEntityService, TrackDechetsService } from 'services';
import { DISTRIBUTOR_ROLES } from 'utils/constants';
import {
  DATE_FORMAT, formatDate, FormHelper, translate, UserHelper
} from 'utils/helpers';
import { SelectItem } from 'utils/types';

import { ModalHeader } from './ModalHeader';

export const CheckPendingMovementModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, fluidOptions
}) => {
  const { fluidStore } = useStores();
  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);

  const {
    response: tdConnectionStatus,
    isLoading: isCheckingStatus
  } = useFetch(() => TrackDechetsService.getConnectionStatus(), null, [], false);
  const [currentMovement, setCurrentMovement] = useState<FluidMovementView>();
  const [operationOptions, setOperationOptions] = useState<SelectItem[]>([]);
  const [searchSuppliersOrRecipients, setSearchSuppliersOrRecipients] = useState('');
  const [suppliersOrRecipients, setSuppliersOrRecipients] = useState<SelectItem[]>([]);
  const [isLoadingSuppliersOrRecipients, setIsLoadingSuppliersOrRecipients] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formState, { number, text }] = useFormState({
    ...defaultValues,
    movementDate: formatDate(new Date())
  });

  const formattedfluidOptionsExtended = fluidStore.fluidExtendedOptions.map(fluid => ({
    value: fluid.id,
    label: fluid.name
  }));

  useEffect(() => {
    FluidMovementsService.getOperationOptions().then(setOperationOptions);
  }, []);

  useEffect(() => {
    LegalEntityService.getLegalEntitiesOptionsFiltered({ types: ['SUPPLIER'] })
      .then(setSuppliersOrRecipients)
      .finally(() => setIsLoadingSuppliersOrRecipients(false));
  }, []);

  useEffect(() => {
    if (defaultValues.hashId) {
      setIsLoading(true);

      FluidMovementsService.getFluidMovement(defaultValues.hashId)
        .then((resp) => {
          setCurrentMovement(resp);
          Object.entries(resp).forEach(([key, value]) => formState.setField(key as any, value));
          const selectedFluid = formattedfluidOptionsExtended.find(f => `${f.value}` === resp.fluid?.value);
          formState.setField('fluid', selectedFluid);
          formState.setField('measuredAmount', resp.measuredAmount ? resp.measuredAmount : resp.theoricalAmount);

          formState.setField('bottle', resp.bottle
            ? {
              value: resp.bottle.id,
              label: resp.bottle.identifier,
              fluid: resp.bottle.fluid
            }
            : null);
        }).finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [defaultValues.hashId]);

  const getAsyncSuppliersOrRecipientsOptions = useCallback(inputValue => new Promise((resolve) => {
    if (!inputValue) {
      setIsLoadingSuppliersOrRecipients(false);
      return;
    }
    setIsLoadingSuppliersOrRecipients(true);
    if (inputValue.length < 3) {
      return;
    }

    LegalEntityService.getLegalEntitiesOptionsFiltered({ freeSearch: inputValue, types: ['SUPPLIER'] })
      .then(resolve)
      .finally(() => setIsLoadingSuppliersOrRecipients(false));
  }), []);

  const debouncedSuppliersOrRecipientsOptions = debounce(getAsyncSuppliersOrRecipientsOptions, 500);

  const handleValidateModal = (e) => {
    e.preventDefault();
    const request = {
      ...formState.values,
      hashId: currentMovement.hashId,
      fluid: FormHelper.SELECT_ADAPTOR_TO_BACK(formState.values.fluid),
      operationCode: FormHelper.SELECT_ADAPTOR_TO_BACK(formState.values.operationCode),
      nextRecipient: formState.values.nextRecipient ? { hashId: formState.values.nextRecipient.value } : null,
      theoricalAmount: Number(formState.values.theoricalAmount),
      measuredAmount: Number(formState.values.measuredAmount)
    };

    onConfirm(request);

    if (closeOnSubmit) {
      onClose();
    }
  };

  const displayNoOptionAvailable = useCallback((search) => {
    if (!search || search.length < 3) {
      return (
        <TextNoOptionsAvailable>{translate('modalFluidMovement.startSearch')}</TextNoOptionsAvailable>
      );
    }
    return (
      <TextNoOptionsAvailable>{translate('modalFluidMovement.noInstitution')}</TextNoOptionsAvailable>
    );
  }, []);

  const getModalMinSizeStyle = useCallback(() => {
    let minWidth = 700;
    let minHeight = 650;

    if (isDistributor) {
      minWidth = 900;
    }
    if (isMobileOnly) {
      minWidth = 200;
      minHeight = 500;
    }
    return { minWidth: `${minWidth}px`, minHeight: `${minHeight}px` };
  }, [isDistributor]);

  if (isCheckingStatus || !tdConnectionStatus || isLoading) {
    return (
      <SkeletonForm />
    );
  }

  return (
    <form autoComplete="off" style={getModalMinSizeStyle()} onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <CustomIcon icon={<FontAwesomeIcon icon={faTint} />} marginRight="1rem" />
        {translate('modalFluidMovement.editFluidMovement')}
      </ModalHeader>

      <DialogContent>
        <FlexContainer flexDirection="column">
          <TextField
            error={!!formState.errors.bottle}
            label={translate('modalFluidMovement.bottleIdentifier')}
            name="bottle"
            required
            style={{ margin: '5px 0' }}
            value={formState.values.bottle?.label}
            onChange={e => formState.setField('bottle', { ...formState.values.bottle, label: e.target.value })}
          />

          <TextField
            disabled
            error={!!formState.errors.supplierOrRecipient}
            label={translate('modalFluidMovement.supplierOrRecipient')}
            name="supplierOrRecipient"
            style={{ margin: '5px 0' }}
            value={formState.values.supplierOrRecipient?.label}
          />

          <Question label={`${translate('common.fluid')} :`}>
            <Select
              id="selectFluid"
              isClearable
              isDisabled={defaultValues.hashId}
              isSearchable
              menuPortalTarget={document.body}
              noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
              options={fluidOptions}
              placeholder={translate('common.searchByFluidType')}
              styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
              value={(formState.values.fluid && formState.values.fluid.value) ? formState.values.fluid : ''}
              onChange={value => formState.setField('fluid', value)}
            />
            <RequiredSelectHack value={formState.values.fluid || ''} />
          </Question>

          <FormLine>
            <TextField
              error={!!formState.errors.measuredAmount}
              label={translate('common.measuredQuantity')}
              name="measuredAmount"
              required
              {...number('measuredAmount')}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>
              }}
              inputProps={{
                step: '0.01',
                min: currentMovement.bsffIdentifier ? 1 : 0
              }}
              style={{ marginTop: '.5rem' }}
            />
            <CustomTooltip text={translate('modalFluidMovement.tooltipMeasured')} />
          </FormLine>

          <FormLine>
            <Datepicker
              dataCy="createFluidMovementDate"
              disableFuture
              format={DATE_FORMAT}
              label="common.receptionDate"
              required
              style={{ marginTop: '.5rem' }}
              value={formState.values.movementDate}
              onChange={date => formState.setField('movementDate', date)}
            />
          </FormLine>

          {currentMovement.movementType.value === 'PENDING_TRANSPORT' && (
            <TextField
              error={!!formState.errors.transporterImat}
              label={translate('common.transporterImat')}
              name="transporterImat"
              required
              {...text('transporterImat')}
            />
          )}

          <Question label={`${translate('common.operation')} :`}>
            <Select
              id="selectOperation"
              isClearable
              isSearchable
              menuPortalTarget={document.body}
              noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
              options={operationOptions}
              styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
              value={(formState.values.operationCode && formState.values.operationCode.value) ? formState.values.operationCode : ''}
              onChange={value => formState.setField('operationCode', value)}
            />
            <RequiredSelectHack value={formState.values.operationCode || ''} />
          </Question>

          {currentMovement.movementType.value === 'PENDING_TRANSPORT' && (
            <TextField
              error={!!formState.errors.transporterImat}
              label={translate('common.transporterImat')}
              name="transporterImat"
              required
              {...text('transporterImat')}
            />
          )}

          {formState.values.operationCode?.value && ['R12', 'R13', 'D13', 'D14', 'D15'].includes(formState.values.operationCode.value) && (
            <Question label={translate('common.nextRecipient')}>
              <AsyncSelect
                cacheOptions
                defaultOptions={suppliersOrRecipients}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                id="selectInstitution"
                inputValue={searchSuppliersOrRecipients}
                isClearable
                isLoading={isLoadingSuppliersOrRecipients}
                isSearchable
                loadingMessage={() => translate('modalFluidMovement.loadingInstitutions')}
                loadOptions={debouncedSuppliersOrRecipientsOptions}
                menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                menuPortalTarget={document.body}
                noOptionsMessage={() => displayNoOptionAvailable(searchSuppliersOrRecipients)}
                placeholder={translate('common.searchByNameSiret')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                value={formState.values.nextRecipient || null}
                onChange={select => formState.setField('nextRecipient', select)}
                onInputChange={value => setSearchSuppliersOrRecipients(value)}
              />
              <RequiredSelectHack value={formState.values.nextRecipient || ''} />
            </Question>
          )}

          {formState.values.operationCode?.value === 'X' && (
            <Question label={`${translate('modalFluidMovement.denyComment')} :`}>
              <TextField
                InputLabelProps={{ required: false }}
                label={translate('modalFluidMovement.denyCommentLabel')}
                multiline
                name="comment"
                required
                rows={3}
                {...text('comment')}
              />
            </Question>
          )}
        </FlexContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          type="submit"
          onClick={handleValidateModal}
        >
          {translate('button.save')}
        </Button>
      </DialogActions>
    </form>
  );
};
