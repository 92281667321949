/* eslint-disable react/jsx-no-duplicate-props */
import { faTint } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, InputAdornment, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  CustomTooltip,
  FlexContainer,
  FontIcon,
  ItemOption,
  ItemSelected,
  Question,
  RequiredSelectHack, Text
} from 'components';
import { useStores } from 'hooks';
import { BottleView, FluidManipulationEntryView } from 'models';
import { ManipulationCategory } from 'models/detail/FluidManipulationEntryView';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import Select from 'react-select';
import { useFormState } from 'react-use-form-state';
import { FluidsService } from 'services';
import styled from 'styled-components';
import { COUNTRY_CODE, FLUID_MANIPULATION_LIST_INPUT, FLUID_MANIPULATION_LIST_OUTPUT } from 'utils/constants';
import {
  BottleHelper, formatDateString, FormHelper, InterventionHelper, translate
} from 'utils/helpers';
import { SelectItem } from 'utils/types';

import { ModalHeader } from './ModalHeader';

const SelectGroupLabel = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  color: var(--turquoise);
  font-weight: 600;
`;

export const AddFluidManipulationModal = ({
  countryCode,
  currentEntry,
  expectedAmount,
  expectedFluid,
  fluidManipulationsInput = [],
  fluidManipulationsOutput = [],
  manipulationType,
  onClose,
  onSubmit
}: {
  countryCode: string,
  currentEntry: FluidManipulationEntryView,
  expectedAmount: number,
  expectedFluid: SelectItem,
  fluidManipulationsInput: FluidManipulationEntryView[],
  fluidManipulationsOutput: FluidManipulationEntryView[],
  manipulationType: ManipulationCategory,
  onClose: (e?: any) => void,
  onSubmit: (manipulation: FluidManipulationEntryView) => void
}) => {
  const { userStore } = useStores();
  const alreadyAddedManipulations = manipulationType === 'output' ? fluidManipulationsOutput : fluidManipulationsInput;
  const fixedSelectedFluid = alreadyAddedManipulations.length > 0 ? alreadyAddedManipulations[0].fluid : undefined;

  const [fluidOptions, setFluidOptions] = useState([]);
  const [availableBottleOptions, setAvailableBottleOptions] = useState([]);
  const [isRetrievalThresholdReached, setIsRetrievalThresholdReached] = useState(false);
  const [formState, { number }] = useFormState<FluidManipulationEntryView>({
    ...currentEntry,
    type: manipulationType,
    fluid: (currentEntry && currentEntry.fluid) || fixedSelectedFluid || expectedFluid
  });

  const isCanada = countryCode === COUNTRY_CODE.CA;

  const FLUID_MANIPULATION_LIST_INPUT_TRANSLATED = FLUID_MANIPULATION_LIST_INPUT.map(manip => ({
    ...manip,
    label: translate(manip.label)
  }));

  const FLUID_MANIPULATION_LIST_OUTPUT_TRANSLATED = FLUID_MANIPULATION_LIST_OUTPUT.map(manip => ({
    ...manip,
    label: translate(manip.label)
  }));

  const manipulationTypeData = {
    input: {
      title: translate('modalFluidManipulation.inputTitle'),
      questionLabel: translate('modalFluidManipulation.inputQuestionLabel'),
      options: FLUID_MANIPULATION_LIST_INPUT_TRANSLATED,
      amountLabel: translate('modalFluidManipulation.inputAmountLabel')
    },
    output: {
      title: translate('modalFluidManipulation.outputTitle'),
      questionLabel: translate('modalFluidManipulation.outputQuestionLabel'),
      options: FLUID_MANIPULATION_LIST_OUTPUT_TRANSLATED,
      amountLabel: translate('modalFluidManipulation.outputAmountLabel')
    }
  };

  const completeBottleList = useCallback(({ bottles, addMapping = false }: {addMapping?: boolean, bottles: BottleView[]}) => {
    let completeBottles = bottles;
    if (addMapping) {
      completeBottles = bottles.map(bottle => ({
        ...bottle,
        value: bottle.id,
        label: bottle.identifier
      }));
    }

    // Filter bottles to avoid two movements of same type with the same bottle
    setAvailableBottleOptions(completeBottles.filter((b) => {
      switch (formState.values.manipulationType?.value) {
        case 'FLUID_FOR_TREATMENT_OUTPUT':
        case 'FLUID_FOR_REUSE_OUTPUT':
          return currentEntry?.fluidBottle?.id === b.id || !fluidManipulationsOutput.find(fm => fm.fluidBottle.id === b.id);
        case 'NEW_FLUID_INPUT':
        case 'RECYCLED_FLUID_INPUT':
        case 'REGENERATED_FLUID_INPUT':
          return currentEntry?.fluidBottle?.id === b.id || !fluidManipulationsInput.find(fm => fm.fluidBottle.id === b.id);
        default:
          return false;
      }
    }));
  }, [currentEntry, fluidManipulationsOutput, fluidManipulationsInput, formState.values.manipulationType]);

  useEffect(() => {
    if (userStore.isOffline) {
      InterventionHelper.loadFluidsStored()
        .then(fluids => setFluidOptions(fluids));
    } else {
      FluidsService.getFluidOptions()
        .then(fluids => setFluidOptions(fluids));
    }
  }, [userStore.isOffline]);

  // Fetch the bottles corresponding to the manipulation type and the equipment fluid
  useEffect(() => {
    if (formState.values.manipulationType) {
      if (userStore.isOffline) {
        InterventionHelper.loadBottlesStored()
          .then((bottles) => {
            // Filter bottles on manipulationType & fluidHashId on frontend
            const bottlesFiltered = bottles
              .filter(bottle => bottle.fluid?.value === formState.values.fluid?.value)
              .filter((bottle) => {
                switch (formState.values.manipulationType?.value) {
                  case 'FLUID_FOR_TREATMENT_OUTPUT':
                    return bottle.bottleType?.value === 'RETRIEVAL_BOTTLE';
                  case 'FLUID_FOR_REUSE_OUTPUT':
                    return bottle.bottleType?.value === 'RETRIEVAL_BOTTLE' || bottle.bottleType?.value === 'TRANSFER_BOTTLE';
                  case 'NEW_FLUID_INPUT':
                    return bottle.bottleType?.value === 'NEW_BOTTLE';
                  case 'RECYCLED_FLUID_INPUT':
                    return bottle.bottleType?.value === 'RETRIEVAL_BOTTLE' || bottle.bottleType?.value === 'TRANSFER_BOTTLE';
                  case 'REGENERATED_FLUID_INPUT':
                    return bottle.bottleType?.value === 'NEW_BOTTLE';
                  default:
                    return bottle;
                }
              });

            completeBottleList({ bottles: bottlesFiltered });
          });
      } else {
        FluidsService.getBottleOptionsExtended({
          manipulationType: FormHelper.SELECT_ADAPTOR_TO_BACK(formState.values.manipulationType),
          fluidId: (formState.values.fluid && formState.values.fluid.value) || null
        }).then(bottles => completeBottleList({ bottles, addMapping: true }));
      }
    }
    // eslint-disable-next-line
  }, [userStore.isOffline, formState.values.manipulationType, formState.values.fluid]);

  useEffect(() => {
    const shouldPrintThresholdWarningCapacity = (fluidAmount: number) => {
      const { maximumCapacity, bottleType, theoricalAmount } = formState.values.fluidBottle;
      if (!maximumCapacity || bottleType.key !== 'RETRIEVAL_BOTTLE') {
        return false;
      }
      const thresholdCapacity = (maximumCapacity * 0.8);
      const totalAmount = (theoricalAmount || 0) + fluidAmount;
      return totalAmount >= thresholdCapacity;
    };

    if (formState.values.fluidBottle) {
      setIsRetrievalThresholdReached(shouldPrintThresholdWarningCapacity(Number(formState.values.fluidAmount)));
    }
  }, [formState.values.fluidAmount, formState.values.fluidBottle]);

  useEffect(() => {
    // When adding a fluid output, try to prefill some fields
    if (manipulationType === 'output' && !currentEntry) {
      if (fluidManipulationsOutput.length === 0) {
        // Prefill the fluid and fluid amount
        formState.setField('fluid', fixedSelectedFluid || expectedFluid);
        formState.setField('fluidAmount', expectedAmount);
      } else {
        // Prefill the fluid
        formState.setField('fluid', fluidManipulationsOutput[0].fluid);
      }
      if (isCanada) {
        formState.setField('bottleControlResult', 'SUCCESS');
        formState.setField('fluidKeptByOwner', false);
      }
    }

    // When adding a fluid input, try using the fluid for reuse output to prefill some fields
    if (manipulationType === 'input' && !currentEntry) {
      const fluidForReuseOutputs = fluidManipulationsOutput.filter(manip => manip.manipulationType.value === 'FLUID_FOR_REUSE_OUTPUT');
      if (fluidForReuseOutputs.length === 1 && fluidManipulationsInput.length === 0) {
        // Prefill the amount with the output amount
        formState.setField('fluidAmount', fluidForReuseOutputs[0].fluidAmount);
        const recycledFluidInputs = fluidManipulationsOutput.filter(manip => manip.manipulationType.value === 'RECYCLED_FLUID_INPUT');
        if (recycledFluidInputs.length === 0) {
          // Prefill the type, fluid and fluid bottle
          formState.setField('manipulationType', FLUID_MANIPULATION_LIST_INPUT_TRANSLATED.find(manip => manip.value === 'RECYCLED_FLUID_INPUT'));
          formState.setField('fluid', fluidForReuseOutputs[0].fluid);
          formState.setField('fluidBottle', fluidForReuseOutputs[0].fluidBottle);
        }
      } else {
        // Prefill the amount with the equipment capacity
        formState.setField('fluidAmount', expectedAmount);
      }
    }
    // eslint-disable-next-line
  }, [alreadyAddedManipulations, manipulationType, fluidManipulationsOutput]);

  const handleValidateModal = (e) => {
    e.preventDefault();

    onSubmit({
      ...formState.values,
      fluidAmount: Number(formState.values.fluidAmount)
    });
  };

  const formatGroupLabel = data => (
    <SelectGroupLabel>
      <span>{data.label}</span>
    </SelectGroupLabel>
  );

  const CustomOption = ({ data, innerProps }) => (
    <ItemOption {...innerProps} isInsideSelect>
      <article>
        {BottleHelper.getTranslatedType({
          type: data.bottleType.key,
          isNewFluid: data.isNewFluid,
          identifier: data.identifier
        })}
      </article>
    </ItemOption>
  );

  return (
    <Fragment>
      <form onSubmit={handleValidateModal}>
        <ModalHeader onClose={onClose}>
          <FontAwesomeIcon icon={faTint} />
          {currentEntry ? translate('modalFluidManipulation.editManipulation') : manipulationTypeData[manipulationType].title}
        </ModalHeader>

        <DialogContent style={{ minHeight: '360px' }}>
          <FlexContainer flexDirection="column">
            <Question label={manipulationTypeData[manipulationType].questionLabel}>
              <Select
                formatGroupLabel={formatGroupLabel}
                id="selectManipulationType"
                isClearable
                menuPortalTarget={document.body}
                name="manipulationType"
                noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                options={manipulationTypeData[manipulationType].options}
                placeholder={translate('modalFluidManipulation.selectManipulation')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                value={formState.values.manipulationType || ''}
                onChange={(value) => {
                  formState.setField('manipulationType', value);
                  formState.setField('fluidBottle', null);
                }}
              />
              <RequiredSelectHack value={formState.values.manipulationType || ''} />
            </Question>

            {formState.values.manipulationType && (
              <Fragment>
                <Question label={translate('modalFluidManipulation.fluidReference')}>
                  <Select
                    id="selectFluid"
                    isClearable
                    isDisabled={!!fixedSelectedFluid}
                    menuPortalTarget={document.body}
                    name="fluid"
                    noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                    options={fluidOptions}
                    placeholder={translate('modalFluidManipulation.fluidReference')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                    value={formState.values.fluid || ''}
                    onChange={(value) => {
                      formState.setField('fluid', value);
                      formState.setField('fluidBottle', null);
                    }}
                  />
                  <RequiredSelectHack value={formState.values.fluid || ''} />
                </Question>
                <Question
                  icon={isRetrievalThresholdReached ? (
                    <CustomTooltip
                      size="xs"
                      text={translate('warnings.bottle.retrievalBottleThreshold')}
                    />
                  ) : null}
                  label={translate('modalFluidManipulation.bottleUsed')}
                >
                  {!formState.values.fluidBottle ? (
                    <div>
                      <Select
                        components={{ Option: CustomOption }}
                        id="selectFluidBottle"
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        name="fluidBottle"
                        noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                        options={availableBottleOptions}
                        placeholder={translate('modalFluidManipulation.selectBottleUsed')}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 3000 }) }}
                        value={formState.values.fluidBottle || ''}
                        onChange={(value) => {
                          formState.setField('fluidBottle', value);
                        }}
                      />
                      <RequiredSelectHack value={formState.values.fluidBottle || ''} />
                    </div>
                  ) : (
                    <ItemSelected
                      deleteAction={() => {
                        formState.setField('fluidBottle', null);
                      }}
                    >
                      <ItemOption>
                        <FontIcon icon="icon-container" />
                        <article>
                          <Text fontSize="1.8rem" margin="0">{formState.values.fluidBottle.identifier}</Text>
                          <Text color="var(--grey)" fontSize="1.4rem" margin="0">
                            {`${translate('common.quantity')} : ${formState.values.fluidBottle.measuredAmount}kg`}
                          </Text>
                          <Text color="var(--grey)" fontSize="1.4rem" margin="0">{formatDateString(formState.values.fluidBottle.receptionDate)}</Text>
                        </article>
                      </ItemOption>
                    </ItemSelected>
                  )}
                </Question>
                <FlexContainer alignItems="center" justifyContent="space-between" style={{ minWidth: '80%' }}>
                  <TextField
                    error={formState.errors.fluidAmount && formState.errors.fluidAmount !== null}
                    label={manipulationTypeData[manipulationType].amountLabel}
                    name="fluidAmount"
                    required
                    style={{ minWidth: '80%' }}
                    {...number('fluidAmount')}
                    // Here we need to have 2 seperate inputProps :
                    // endAdornment is not recognized as basic dom attribute
                    // and if we put the step in the first InputProps, it is not taken into account
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>
                    }}
                    inputProps={{
                      step: '0.01',
                      min: 0.01
                    }}
                  />
                  <CustomTooltip
                    text={translate('modalFluidManipulation.warningFluidAmount', { fluidAmount: expectedAmount })}
                  />
                </FlexContainer>
                {manipulationType === 'output' && isCanada && (
                  <Question label={translate('modalFluidManipulation.bottleControlResult')}>
                    <ToggleButtonGroup
                      aria-label={translate('modalFluidManipulation.bottleControlResult')}
                      exclusive
                      size="medium"
                      style={{ marginBottom: '1rem' }}
                      value={formState.values.bottleControlResult}
                      onChange={(event, value) => {
                        if (value === null) return;
                        formState.setField('bottleControlResult', value);
                      }}
                    >
                      <ToggleButton
                        aria-label={translate('print.enum.BottleControlResult.SUCCESS')}
                        value='SUCCESS'
                      >
                        {translate('print.enum.BottleControlResult.SUCCESS')}
                      </ToggleButton>
                      <ToggleButton
                        aria-label={translate('print.enum.BottleControlResult.FAILURE')}
                        value='FAILURE'
                      >
                        {translate('print.enum.BottleControlResult.FAILURE')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Question>
                )}
                {manipulationType === 'output' && isCanada && (
                  <Question label={translate('modalFluidManipulation.fluidKeptByOwner')}>
                    <ToggleButtonGroup
                      aria-label={translate('modalFluidManipulation.fluidKeptByOwner')}
                      exclusive
                      size="medium"
                      style={{ marginBottom: '1rem' }}
                      value={formState.values.fluidKeptByOwner}
                      onChange={(event, value) => {
                        if (value === null) return;
                        formState.setField('fluidKeptByOwner', value);
                      }}
                    >
                      <ToggleButton
                        aria-label={translate('common.yes')}
                        value
                      >
                        {translate('common.yes')}
                      </ToggleButton>
                      <ToggleButton
                        aria-label={translate('common.no')}
                        value={false}
                      >
                        {translate('common.no')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Question>
                )}
              </Fragment>
            )}
          </FlexContainer>

        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{translate('button.cancel')}</Button>
          <Button color="primary" type="submit">{translate('button.save')}</Button>
        </DialogActions>
      </form>
    </Fragment>
  );
};
