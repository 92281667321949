/* eslint-disable react/jsx-no-duplicate-props */
import {
  DialogContent, InputAdornment, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  CustomIcon, CustomTooltip, Datepicker, FlexContainer, FontIcon, FormLine, Question, RequiredSelectHack, SkeletonForm, Text
} from 'components';
import { useStores } from 'hooks';
import { ModalProps } from 'hooks/ModalProvider';
import { BottleView } from 'models';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import { useFormState } from 'react-use-form-state';
import { FluidsService } from 'services';
import shortid from 'shortid';
import { BOTTLE_TYPES, STOCK_ROLES_WITH_INTERVENTION_MANAGER } from 'utils/constants';
import {
  BottleHelper,
  FormHelper,
  UserHelper,
  formatDate,
  translate
} from 'utils/helpers';
import { ModalHeader } from './ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const CreateBottleModal = ({
  bottleId,
  isInit = false,
  onSubmit,
  onClose
}: ModalProps<BottleView> & {
  bottleId: string,
  isInit?: boolean
}) => {
  const { fluidStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, { text, number }] = useFormState<BottleView>(new BottleView());

  const formattedfluidOptionsExtended = fluidStore.fluidExtendedOptions.map(fluid => ({
    value: fluid.id,
    label: fluid.name
  }));

  const canValidate = bottleId && !formState.values.validated && UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER);

  useEffect(() => {
    if (!bottleId) {
      if (!isInit) {
        formState.setField('stringType', 'RETRIEVAL_BOTTLE');
        formState.setField('receptionDate', formatDate(new Date()));
      }
      return;
    }
    setIsLoading(true);

    FluidsService.getBottle(bottleId)
      .then((resp) => {
        Object.entries(resp).forEach(([key, value]) => formState.setField(key as any, value));
        const selectedFluid = formattedfluidOptionsExtended.find(f => `${f.value}` === resp.fluid?.value);
        formState.setField('fluid', selectedFluid);
        formState.setField('stringType', resp.bottleType?.value as any);
      }).finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [bottleId]);

  const handleValidateModal = (e) => {
    e.preventDefault();
    onSubmit({
      ...formState.values,
      bottleType: {
        value: formState.values.stringType,
        label: formState.values.stringType
      },
      initialAmount: formState.values.initialAmount && Number(formState.values.initialAmount),
      measuredAmount: formState.values.measuredAmount && Number(formState.values.measuredAmount),
      maximumCapacity: formState.values.maximumCapacity && Number(formState.values.maximumCapacity),
      theoricalAmount: formState.values.theoricalAmount && Number(formState.values.theoricalAmount),
      volume: formState.values.volume && Number(formState.values.volume),
      fluid: FormHelper.SELECT_ADAPTOR_TO_BACK(formState.values.fluid)
    });
  };

  const displayHeader = () => {
    if (bottleId) {
      return translate('modalBottle.editBottle');
    }
    if (isInit) {
      return translate('modalBottle.registerBottle');
    }
    return translate('modalBottle.newBottle');
  };

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <CustomIcon icon={<FontIcon fontSize="2.4rem" icon="icon-container" marginLeft="-0.6rem" />} marginRight="1rem" />
        {displayHeader()}
      </ModalHeader>

      {isLoading ? (
        <SkeletonForm />
      ) : (
        <>
          <DialogContent>
            <FlexContainer flexDirection="column">
              <Question label={translate('modalBottle.bottleType')}>
                <ToggleButtonGroup
                  aria-label={translate('modalBottle.bottleType')}
                  data-cy="selectBottleType"
                  exclusive
                  name="bottleType"
                  size="medium"
                  {...text('stringType')}
                  onChange={(event, value) => {
                    if (value === null) return;
                    formState.setField('stringType', value);
                  }}
                >
                  {BOTTLE_TYPES.map((type) => {
                    if (!bottleId && !isInit && type.key === 'NEW_BOTTLE') {
                      return null;
                    }
                    return (
                      <ToggleButton
                        aria-label={BottleHelper.getTranslatedType({ type: type.key })}
                        disabled={bottleId && !formState.values.canDelete}
                        key={shortid.generate()}
                        value={type.key}
                      >
                        {BottleHelper.getTranslatedType({ type: type.key })}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Question>

              <FormLine>
                <TextField
                  error={formState.errors.identifier && formState.errors.identifier !== null}
                  label={translate('common.identifier')}
                  name="identifier"
                  required
                  {...text('identifier')}
                  inputProps={{ pattern: '.*\\S.*' }}
                />
                <CustomTooltip text={translate('warnings.bottle.identifierMaxLength')} />
              </FormLine>

              {/* <Datepicker
                dataCy="bottleValidityDate"
                label="common.bottleValidityDate"
                name="bottleValidityDate"
                style={{ marginTop: '.5rem' }}
                value={formState.values.validityDate}
                onChange={date => formState.setField('validityDate', date)}
              /> */}

              {!bottleId && (
                <Datepicker
                  dataCy="createBottleModalReceptionDate"
                  disableFuture
                  label="common.receptionDate"
                  name="receptionDate"
                  style={{ marginTop: '.5rem' }}
                  value={formState.values.receptionDate}
                  onChange={date => formState.setField('receptionDate', date)}
                />
              )}

              <Question label={`${translate('modalBottle.chooseFluid')} :`}>
                <FormLine style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                  <Select
                    id="selectFluid"
                    isClearable
                    isDisabled={bottleId && !formState.values.canDelete}
                    isSearchable
                    menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                    options={fluidStore.fluidOptions}
                    placeholder={translate('common.searchByFluidType')}
                    required={formState.values.stringType === 'NEW_BOTTLE'}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                    value={formState.values.fluid || ''}
                    onChange={value => formState.setField('fluid', value)}
                  />
                  {formState.values.stringType !== 'NEW_BOTTLE' && (
                    <CustomTooltip text={translate('modalBottle.fluidNotMandatory')} />
                  )}
                </FormLine>
                {formState.values.stringType === 'NEW_BOTTLE' && (
                  <RequiredSelectHack value={formState.values.fluid || ''} />
                )}
              </Question>

              {formState.values.stringType === 'NEW_BOTTLE' && (
                <Fragment>
                  <ToggleButtonGroup
                    aria-label={translate('common.fluidType')}
                    data-cy="isNewFluid"
                    exclusive
                    size="small"
                    value={formState.values.isNewFluid}
                    onChange={(event, value) => {
                      if (value === null) return;
                      formState.setField('isNewFluid', value);
                    }}
                  >
                    <ToggleButton
                      aria-label={translate('fluids.newFluid')}
                      data-cy="newFluid"
                      disabled={bottleId && !formState.values.canDelete}
                      key={shortid.generate()}
                      value
                    >
                      {translate('fluids.newFluid')}
                    </ToggleButton>
                    <ToggleButton
                      aria-label={translate('fluids.regeneratedFluid')}
                      data-cy="regeneratedFluid"
                      disabled={bottleId && !formState.values.canDelete}
                      key={shortid.generate()}
                      value={false}
                    >
                      {translate('fluids.regeneratedFluid')}
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <FlexContainer alignItems="center">
                    <Text margin="0 2rem 0 0">{translate('fluids.boughtInFrance', { country: UserHelper.getDefaultCountry().actualTranslation })}</Text>
                    <ToggleButtonGroup
                      aria-label={translate('fluids.boughtInFrance', { country: UserHelper.getDefaultCountry().actualTranslation })}
                      data-cy="boughtInFrance"
                      exclusive
                      size="small"
                      style={{ marginTop: '1rem' }}
                      value={formState.values.boughtInFrance}
                      onChange={(event, value) => {
                        if (value === null) return;
                        formState.setField('boughtInFrance', value);
                      }}
                    >
                      <ToggleButton
                        aria-label={translate('common.yes')}
                        data-cy="yes"
                        disabled={bottleId && !formState.values.canDelete}
                        key={shortid.generate()}
                        value
                      >
                        {translate('common.yes')}
                      </ToggleButton>
                      <ToggleButton
                        aria-label={translate('common.no')}
                        data-cy="no"
                        disabled={bottleId && !formState.values.canDelete}
                        key={shortid.generate()}
                        value={false}
                      >
                        {translate('common.no')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FlexContainer>
                </Fragment>
              )}

              {formState.values.fluid && (
                <Fragment>
                  {formState.values.stringType === 'NEW_BOTTLE' && !bottleId && (
                    <TextField
                      disabled={!!bottleId}
                      error={formState.errors.initialAmount && formState.errors.initialAmount !== null}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>
                      }}
                      inputProps={{
                        step: '0.01',
                        min: 0
                      }}
                      label={translate('common.initialQuantity')}
                      name="initialAmount"
                      style={{ marginTop: '.5rem' }}
                      {...number('initialAmount')}
                    />
                  )}

                  {!bottleId && (
                    <TextField
                      disabled={bottleId && !formState.values.canDelete}
                      error={formState.errors.measuredAmount && formState.errors.measuredAmount !== null}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>
                      }}
                      inputProps={{
                        step: '0.01',
                        min: 0
                      }}
                      label={translate('common.measuredQuantityNow')}
                      name="measuredAmount"
                      required
                      style={{ marginTop: '.5rem' }}
                      {...number('measuredAmount')}
                    />
                  )}
                </Fragment>
              )}

              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>
                }}
                inputProps={{
                  step: '0.01',
                  min: formState.values.measuredAmount || 1
                }}
                label={translate('common.maximumCapacity')}
                name="maximumCapacity"
                style={{ marginTop: '.5rem' }}
                {...number('maximumCapacity')}
              />

              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">L</InputAdornment>
                }}
                inputProps={{
                  step: '0.01'
                }}
                label={translate('common.bottleVolume')}
                name="volume"
                style={{ marginTop: '.5rem' }}
                {...number('volume')}
              />
            </FlexContainer>
          </DialogContent>

          <CustomActionButtons
            forValidation={canValidate}
            isLoading={isLoading}
            onCancel={onClose}
            onClose={onClose}
          />
        </>
      )}
    </form>
  );
};
