import React from 'react';
import shortid from 'shortid';

import {
  Bar, BarChart, CartesianGrid, LabelList, LayoutType, Legend,
  ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts'; // Docs : http://recharts.org/en-US/api

export type BarKeyType = {
  fill: string,
  key: string,
  stackId?: string
}

export const CustomBarChart = ({
  barKeys,
  chartData,
  displayDataInChart = true,
  hideLegend = false,
  labelFontSize = '2rem',
  layout = 'horizontal',
  onClick = () => { },
  unit = 'kg',
  withReferenceLine = false,
  xAxisKey = '',
  xAxisType = 'category',
  yAxisKey = '',
  yAxisType = 'number'
}: {
  barKeys: BarKeyType[],
  chartData: any,
  displayDataInChart?: boolean,
  hideLegend?: boolean,
  labelFontSize?: string,
  layout?: LayoutType,
  onClick?: any,
  unit?: string,
  withReferenceLine?: boolean,
  xAxisKey?: string,
  xAxisType?: 'number' | 'category',
  yAxisKey?: string,
  yAxisType?: 'number' | 'category'
}) => {
  const chartHeight = (60 * chartData.length) + (hideLegend ? 0 : 20);
  return (
    <ResponsiveContainer height={chartHeight}>
      <BarChart barCategoryGap="6%" barGap={1} data={chartData} layout={layout} stackOffset="sign">
        <XAxis dataKey={xAxisKey} type={xAxisType} />
        <YAxis dataKey={yAxisKey} type={yAxisType} />
        <Tooltip />
        {!hideLegend && <Legend />}
        {withReferenceLine && <ReferenceLine isFront x={0} />}
        <CartesianGrid strokeDasharray="3" />
        {barKeys.map(({ key, fill, stackId }) => (
          <Bar
            dataKey={key}
            fill={fill}
            key={shortid.generate()}
            minPointSize={5}
            stackId={stackId}
            unit={unit}
            onClick={onClick}
          >
            <LabelList
              content={({
                value, y, x, width, height
              }) => (
                value && displayDataInChart
                  ? (
                    <g>
                      <text
                        dominantBaseline="middle"
                        fill="var(--white)"
                        fontSize={labelFontSize}
                        style={{ textShadow: '0px 1px 4px rgba(0, 0, 0, 1)', pointerEvents: 'none' }}
                        textAnchor="middle"
                        x={Number(x + width / 2)}
                        y={Number(y + height / 2)}
                      >
                        {`${value} ${unit}`}
                      </text>
                    </g>
                  ) : null
              )}
              dataKey={key}
              position="center"
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
