import {
  faCheck, faFileExcel, faSpinner, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  ButtonBlue, ButtonBlueLight, GenericListContainer, SkeletonMain, SubtitlePage
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { FluidBalanceView } from 'models/detail/FluidBalanceView';
import { useSnackbar } from 'notistack';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import Select from 'react-select';
import { FluidBalancesService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { DISTRIBUTOR_ROLES, FLUID_BALANCE_SYNCHRONIZE_ROLES } from 'utils/constants';
import {
  DocumentHelper, translate, UserHelper
} from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { DatafluidesFluidBalanceService } from '../../services/DatafluidesFluidBalanceService';

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    min-width: 250px;
    margin-bottom: 1rem;
  }
`;

const StyledReportSection = styled.li`
  margin-left: 1rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eeeeee;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .MuiListSubheader-root {
    line-height: 1.6rem;
  }

  .MuiListItemSecondaryAction-root {
    svg {
      position: absolute;
      right: -30px;
    }
  }
`;

const getListHeadersTable = () => [
  {
    name: 'label',
    label: translate('common.fluid')
  },
  {
    name: 'isDetentionAllowed',
    label: translate('pageFluidBalance.isDetentionAllowed')
  },
  {
    name: 'isLoadingAllowed',
    label: translate('pageFluidBalance.isLoadingAllowed')
  },
  {
    name: 'isDeltaValid',
    label: translate('pageFluidBalance.isDeltaValid')
  },
  {
    name: 'isNegative',
    label: translate('pageFluidBalance.isNegative')
  }
];

const ReportSection = ({ title, valueList, dataCy = '' }) => (
  <StyledReportSection data-cy={dataCy}>
    <ul>
      <ListSubheader disableGutters style={{ backgroundColor: 'var(--white)' }}>{title}</ListSubheader>
      {valueList.map(value => (
        <ListItem dense disableGutters key={shortid.generate()}>
          <ListItemText>
            {value.isTotal && (
              <b>{value.label}</b>
            )}
            {!value.isTotal && (
              value.label
            )}
          </ListItemText>
          <ListItemSecondaryAction>
            {value.isTotal && (
              <b>{value.value !== null ? `${value.value} kg` : '-'}</b>
            )}
            {!value.isTotal && (
              value.value !== null ? `${value.value} kg` : '-'
            )}
            {value.icon || ''}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </ul>
  </StyledReportSection>
);

export const FluidBalance = observer(() => {
  const { userStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [allBalances, setAllBalances] = useState<FluidBalanceView[]>([]);
  const [balanceToggleMap, setBalanceToggleMap] = useState<boolean[]>([]);
  const [years, setYears] = useState<SelectItem[]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [yearSelected, setYearSelected] = useState<SelectItem>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);
  const [isDatafluidesBalanceEditable, setIsDatafluidesBalanceEditable] = useState<boolean>(false);
  const [areDatafluidesBalancesEditable, setAreDatafluidesBalancesEditable] = useState<boolean>(false);
  const isInterventionManagerOrAdministrator = UserHelper.hasAccessRight(FLUID_BALANCE_SYNCHRONIZE_ROLES);

  useEffect(() => {
    FluidBalancesService.getYears().then((yearOptions) => {
      if (yearOptions && yearOptions.length > 0) {
        setYears(yearOptions);
        setYearSelected(yearOptions[yearOptions.length - 1]);
      }
    });
  }, []);

  useEffect(() => {
    if (yearSelected && userStore.isViewingInstitution()) {
      setIsLoading(true);
      FluidBalancesService.getAllBalances({ date: yearSelected }).then((response) => {
        if (response && response.length > 0) {
          setAllBalances(response);
          setBalanceToggleMap(new Array(response.length).fill(false));
        }
      }).finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [yearSelected]);

  // Synchronization
  useEffect(() => {
    if (isInterventionManagerOrAdministrator && yearSelected) {
      if (userStore.isViewingInstitution()) {
        const institutionModelId = userStore.currentLevel.value;
        DatafluidesFluidBalanceService.isEditableFluidBalanceFromInstitutionModelId(institutionModelId, yearSelected.value)
          .then(isEditable => setIsDatafluidesBalanceEditable(isEditable))
          .catch(() => setIsDatafluidesBalanceEditable(false));
      } else {
        DatafluidesFluidBalanceService.getInstitutionIdsWithEditableFluidBalancesForYear(yearSelected.value)
          .then((institutionModelIds) => setAreDatafluidesBalancesEditable(institutionModelIds.length > 0))
          .catch(() => setAreDatafluidesBalancesEditable(false));
      }
    }
  }, [isInterventionManagerOrAdministrator, userStore, yearSelected]);

  const toggleFluideExpanded = (index) => {
    const newMap = [...balanceToggleMap];
    newMap[index] = !balanceToggleMap[index];
    setBalanceToggleMap(newMap);
  };

  const getIcon = (isValid, tooltip) => {
    if (isValid) {
      return <FontAwesomeIcon color="var(--green)" icon={faCheck} />;
    }

    return (
      <Tooltip title={tooltip}>
        <IconButton aria-label={tooltip} size="small" style={{ padding: '0' }}>
          <FontAwesomeIcon color="var(--red)" icon={faTimes} />
        </IconButton>
      </Tooltip>
    );
  };

  const checkNegatives = values => values.filter(value => value < 0).length === 0;

  const getIconWithNegative = (checkNegative, isValid, tooltip1, tooltip2) => {
    if (!checkNegative) {
      return (
        <Tooltip title={tooltip2}>
          <IconButton aria-label={tooltip2} size="small" style={{ padding: '0' }}>
            <FontAwesomeIcon color="var(--red)" icon={faTimes} />
          </IconButton>
        </Tooltip>
      );
    }

    if (isValid) {
      return <FontAwesomeIcon color="var(--green)" icon={faCheck} />;
    }

    return (
      <Tooltip title={tooltip1}>
        <IconButton aria-label={tooltip1} size="small" style={{ padding: '0' }}>
          <FontAwesomeIcon color="var(--red)" icon={faTimes} />
        </IconButton>
      </Tooltip>
    );
  };

  const exportReport = (fluidSelected = null) => {
    setIsExporting(true);
    if (userStore.isViewingInstitution()) {
      FluidBalancesService.exportReport({
        date: yearSelected,
        fluid: fluidSelected
      }).then((response) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(response);
        // `export_${yearSelected}_${(fluidSelected && fluidSelected.label) changed in the backend in DATAFLUIDES-451 the 16/11/2023
        downloadLink.download = response.name || `export_${yearSelected}_${(fluidSelected && fluidSelected.label) || ''}`;
        downloadLink.click();
      })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => setIsExporting(false));
    } else {
      const controller = new AbortController();
      const { signal } = controller;
      const timeout = new Promise((res: any) => {
        setTimeout(() => res(), 1500);
      });
      Promise.race([FluidBalancesService.notifExportReport({ date: yearSelected, fluid: fluidSelected }, signal), timeout])
        .then(() => {
          enqueueSnackbar(translate('common.fluidBalanceAsyncDownload'), { variant: 'success', autoHideDuration: 5000 });
          controller.abort();
        })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  const processAfterSynchronization = useCallback((allEntriesAreSynchronized, isMassSynchronization) => {
    let messageCode;
    let snackbarVariant;
    if (allEntriesAreSynchronized) {
      messageCode = isMassSynchronization
        ? 'confirms.fluidBalance.synchronization'
        : 'pageFluidBalance.synchronize.success';
      snackbarVariant = 'success';
    } else {
      messageCode = 'pageFluidBalance.synchronize.notEverything';
      snackbarVariant = 'warning';
    }
    setIsSynchronizing(false);
    enqueueSnackbar(translate(messageCode), { variant: snackbarVariant });
  }, [enqueueSnackbar]);

  const launchSynchronization = useCallback(() => {
    setIsSynchronizing(true);
    if (userStore.isViewingInstitution()) {
      const institutionModelId = userStore.currentLevel.value;
      FluidBalancesService.getFluidBalanceFromYear(yearSelected.value, institutionModelId)
        .then((fluidBalances) => {
          if (!fluidBalances.length) {
            setIsSynchronizing(false);
            enqueueSnackbar(translate('pageFluidBalance.synchronize.empty'), { variant: 'error' });
            return;
          }
          const fluidBalance = fluidBalances[0];
          DatafluidesFluidBalanceService.synchronizeFromDatabilan(fluidBalance)
            .then((allEntriesAreSynchronized) => processAfterSynchronization(allEntriesAreSynchronized, false))
            .catch((error) => {
              setIsSynchronizing(false);
              enqueueSnackbar((error && error.message) || error, { variant: 'error' });
            });
        })
        .catch((error) => {
          setIsSynchronizing(false);
          enqueueSnackbar((error && error.message) || error, { variant: 'error' });
        });
    } else {
      FluidBalancesService.massSynchronizeWithDatafluides(yearSelected.value)
        .then(() => processAfterSynchronization(true, true))
        .catch((error) => {
          setIsSynchronizing(false);
          enqueueSnackbar((error && error.message) || error, { variant: 'error' });
        });
    }
  }, [enqueueSnackbar, processAfterSynchronization, userStore, yearSelected]);

  const checkForAlert = useCallback(
    (balance) => !balance.isAmountHeldValid
      || !balance.isAmountLoadedValid
      || !balance.isDeltaValid
      || !checkNegatives([
        balance.initialAmountHeldNew,
        balance.initialAmountHeldUsed,
        balance.finalAmountHeldNew,
        balance.finalAmountHeldUsed
      ]),
    []
  );

  const getMessageCodeForConfirmationSynchronize = useCallback(() => {
    let isAtLeastOneBalanceWithAlerts = false;
    let isNegativeValues = false;
    if (userStore.isViewingInstitution && allBalances.length) {
      isAtLeastOneBalanceWithAlerts = allBalances.some(balance => checkForAlert(balance));
      isNegativeValues = allBalances.some(balance => !checkNegatives([
        balance.initialAmountHeldNew,
        balance.initialAmountHeldUsed,
        balance.finalAmountHeldNew,
        balance.finalAmountHeldUsed
      ]));
      if (isAtLeastOneBalanceWithAlerts) {
        if (isNegativeValues) {
          return 'pageFluidBalance.confirmation.synchronizeWithAlertAndNegativeValues';
        }
        return 'pageFluidBalance.confirmation.synchronizeWithAlert';
      }
      return 'pageFluidBalance.confirmation.synchronize';
    }
    return 'pageFluidBalance.confirmation.massSynchronize';
  }, [allBalances, checkForAlert, userStore]);

  const synchronizeBalances = useCallback(() => {
    open({
      type: 'WAITING_CONFIRMATION',
      title: translate(
        userStore.isViewingInstitution()
          ? 'pageFluidBalance.actions.synchronize'
          : 'pageFluidBalance.actions.massSynchronize'
      ),
      content: translate(getMessageCodeForConfirmationSynchronize()),
      onConfirm: launchSynchronization
    });
  }, [open, userStore, getMessageCodeForConfirmationSynchronize, launchSynchronization]);

  const getOperatorSection = balance => [
    {
      label: translate('pageFluidBalance.amountBoughtInFrance'),
      value: balance.amountBoughtInFrance
    }, {
      label: translate('pageFluidBalance.amountBoughtOutsideFrance'),
      value: balance.amountBoughtOutsideFrance
    }, {
      label: translate('pageFluidBalance.amountBought'),
      value: balance.amountBought,
      isTotal: true
    }, {
      label: translate('pageFluidBalance.amountLoadedNewMaterial'),
      value: balance.amountLoadedNewMaterial
    }, {
      label: translate('pageFluidBalance.amountLoadedMaintenance'),
      value: balance.amountLoadedMaintenance
    }, {
      label: translate('pageFluidBalance.amountLoaded'),
      value: balance.amountLoaded,
      isTotal: true,
      icon: getIcon(balance.isAmountLoadedValid, translate('pageFluidBalance.isAmountLoadedValidTooltip'))
    }, {
      label: translate('pageFluidBalance.amountRecoveredOutOfOrder'),
      value: balance.amountRecoveredOutOfOrder
    }, {
      label: translate('pageFluidBalance.amountRecoveredMaintenance'),
      value: balance.amountRecoveredMaintenance
    }, {
      label: translate('pageFluidBalance.amountRecovered'),
      value: balance.amountRecovered,
      isTotal: true
    }, {
      label: translate('pageFluidBalance.amountGivenBack'),
      value: balance.amountGivenBack
    }];

  const getDistributorSection = balance => [
    {
      label: translate('pageFluidBalance.amountBoughtInFranceDistributor'),
      value: balance.amountBoughtInFrance
    }, {
      label: translate('pageFluidBalance.amountBoughtOutsideFranceDistributor'),
      value: balance.amountBoughtOutsideFrance
    }, {
      label: translate('pageFluidBalance.amountBoughtDistributor'),
      value: balance.amountBought,
      isTotal: true
    }, {
      label: translate('pageFluidBalance.amountRetrieved'),
      value: balance.amountRetrieved,
      isTotal: true
    }, {
      label: translate('pageFluidBalance.amountSold'),
      value: balance.amountSold,
      isTotal: true
    }, {
      label: translate('pageFluidBalance.amountGivenBackDistributor'),
      value: balance.amountGivenBack
    }, {
      label: translate('pageFluidBalance.amountResetDistributor'),
      value: balance.amountResetDistributor
    }];

  const renderSynchronizeButtonComponent = useCallback(
    (message) => <ButtonBlue
      disabled={
        !yearSelected
        || (userStore.isViewingInstitution() && !isDatafluidesBalanceEditable)
        || (!userStore.isViewingInstitution() && !areDatafluidesBalancesEditable)
        || isSynchronizing
      }
      onClick={() => synchronizeBalances()}
    >
      {isSynchronizing ? (
        <Fragment>
          <FontAwesomeIcon icon={faSpinner} spin />
          {translate(message)}
        </Fragment>
      ) : (
        translate(message)
      )}
    </ButtonBlue>,
    [yearSelected, userStore, isDatafluidesBalanceEditable, areDatafluidesBalancesEditable, isSynchronizing, synchronizeBalances]
  );

  const renderSynchronizeButton = useCallback(() => {
    if (userStore.isViewingInstitution()) {
      const synchronizeButtonCode = 'pageFluidBalance.buttonSynchronize';
      return ((isDatafluidesBalanceEditable && !isSynchronizing)
        ? renderSynchronizeButtonComponent(synchronizeButtonCode)
    : (
      <Tooltip
          disableFocusListener
          style={{ marginRight: '0.5em' }}
          title={translate(
            isSynchronizing
              ? 'pageFluidBalance.toolTip.synchronization.inProgress'
              : 'pageFluidBalance.toolTip.synchronization.notEditable'
          )}
        >
        <span>
          {renderSynchronizeButtonComponent(
              synchronizeButtonCode
            )}
        </span>
      </Tooltip>
      ));
    }
    const massSynchronizeButtonCode = 'pageFluidBalance.buttonMassSynchronize';
    return ((areDatafluidesBalancesEditable && !isSynchronizing)
      ? renderSynchronizeButtonComponent(massSynchronizeButtonCode)
      : (
        <Tooltip
          disableFocusListener
          style={{ marginRight: '0.5em' }}
          title={translate(
            isSynchronizing
              ? 'pageFluidBalance.toolTip.synchronization.inProgress'
              : 'pageFluidBalance.toolTip.synchronization.massNotEditable'
          )}>
          <span>
            {renderSynchronizeButtonComponent(
              massSynchronizeButtonCode
            )}
          </span>
        </Tooltip>
    ));
  }, [userStore, areDatafluidesBalancesEditable, isSynchronizing, renderSynchronizeButtonComponent, isDatafluidesBalanceEditable]);

  return (
    <GenericListContainer>
      <SubtitlePage>{translate('pageFluidBalance.title')}</SubtitlePage>

      <SelectContainer>
        <Select
          id="selectBalanceYear"
          isClearable
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={years}
          placeholder={translate('common.selectYear')}
          value={yearSelected || ''}
          onChange={value => setYearSelected(value)}
        />
        <div className="buttonsContainer" style={{ position: 'relative' }}>
          {isInterventionManagerOrAdministrator && renderSynchronizeButton()}
          <ButtonBlue disabled={!yearSelected || isExporting} onClick={() => exportReport()}>
            {isExporting ? (
              <Fragment>
                <FontAwesomeIcon icon={faSpinner} spin />
                {translate('pageFluidBalance.buttonExporting')}
              </Fragment>
            ) : (
              <Fragment>
                <FontAwesomeIcon icon={faFileExcel} />
                {translate('pageFluidBalance.buttonExportGlobal')}
              </Fragment>
            )}
          </ButtonBlue>
        </div>
      </SelectContainer>

      {isLoading && <SkeletonMain />}

      {!isLoading && yearSelected && allBalances.length > 0 && (
        <div style={{ overflowX: 'auto' }}>
          <Table data-cy="fluidBalanceTable">
            <TableHead>
              <TableRow>
                {getListHeadersTable()
                  .filter(header => !(isDistributor && header.name === 'status'))
                  .map(header => (
                    <TableCell key={header.name}>
                      {header.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allBalances.map((balance, index) => (
                <Fragment key={balance.fluid.value}>
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleFluideExpanded(index)}
                  >
                    <TableCell>
                      {balance.fluid.label}
                    </TableCell>
                    <TableCell>
                      {getIcon(balance.isAmountHeldValid, translate('pageFluidBalance.isAmountHeldValidTooltip'))}
                    </TableCell>
                    <TableCell>
                      {getIcon(balance.isAmountLoadedValid, translate('pageFluidBalance.isAmountLoadedValidTooltip'))}
                    </TableCell>
                    <TableCell>
                      {getIcon(balance.isDeltaValid, translate('pageFluidBalance.isDeltaValidTooltip'))}
                    </TableCell>
                    <TableCell>
                      {getIcon(checkNegatives([
                        balance.initialAmountHeldNew,
                        balance.initialAmountHeldUsed,
                        balance.finalAmountHeldNew,
                        balance.finalAmountHeldUsed
                      ]), translate('pageFluidBalance.isAmountHeldNegativeTooltip'))}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}>
                      <Collapse in={balanceToggleMap[index]}>
                        <List>
                          <ReportSection
                            dataCy="fluidBalanceStocks"
                            title={translate('pageFluidBalance.section1title')}
                            valueList={[
                              {
                                label: `${translate('pageFluidBalance.initialAmountHeldNew')} 01/01/${yearSelected.label}`,
                                value: balance.initialAmountHeldNew
                              }, {
                                label: `${translate('pageFluidBalance.initialAmountHeldUsed')} 01/01/${yearSelected.label}`,
                                value: balance.initialAmountHeldUsed
                              }, {
                                label: `${translate('pageFluidBalance.initialAmountHeld')} 01/01/${yearSelected.label}`,
                                value: balance.initialAmountHeld,
                                isTotal: true,
                                icon: getIconWithNegative(
                                  checkNegatives([balance.initialAmountHeldNew, balance.initialAmountHeldUsed]),
                                  balance.isAmountHeldValid,
                                  translate('pageFluidBalance.isAmountHeldValidTooltip'),
                                  translate('pageFluidBalance.isAmountHeldNegativeTooltip')
                                )
                              }, {
                                label: `${translate('pageFluidBalance.finalAmountHeldNew')} 31/12/${yearSelected.label}`,
                                value: balance.finalAmountHeldNew
                              }, {
                                label: `${translate('pageFluidBalance.finalAmountHeldUsed')} 31/12/${yearSelected.label}`,
                                value: balance.finalAmountHeldUsed
                              }, {
                                label: `${translate('pageFluidBalance.finalAmountHeld')} 31/12/${yearSelected.label}`,
                                value: balance.finalAmountHeld,
                                isTotal: true,
                                icon: getIconWithNegative(
                                  checkNegatives([balance.finalAmountHeldNew, balance.finalAmountHeldUsed]),
                                  balance.isAmountHeldValid,
                                  translate('pageFluidBalance.isAmountHeldValidTooltip'),
                                  translate('pageFluidBalance.isAmountHeldNegativeTooltip')
                                )
                              }
                            ]}
                          />
                          <ReportSection
                            title={translate('pageFluidBalance.section2title')}
                            valueList={isDistributor ? getDistributorSection(balance) : getOperatorSection(balance)}
                          />
                          <ReportSection
                            title={translate('pageFluidBalance.deltaTitle')}
                            valueList={[
                              {
                                label: translate('pageFluidBalance.deltaInputs'),
                                value: balance.totalInput
                              }, {
                                label: isDistributor ? translate('pageFluidBalance.deltaOutputsDistributor') : translate('pageFluidBalance.deltaOutputs'),
                                value: balance.totalOutput
                              }, {
                                label: translate('pageFluidBalance.deltaTotal'),
                                value: balance.totalDelta,
                                isTotal: true,
                                icon: getIcon(balance.isDeltaValid, translate('pageFluidBalance.isDeltaValidTooltip'))
                              }
                            ]}
                          />
                          <ReportSection
                            title={translate(isDistributor ? 'pageFluidBalance.section3titleDistributor' : 'pageFluidBalance.section3title')}
                            valueList={[
                              {
                                label: translate(isDistributor ? 'pageFluidBalance.amountHandedOverDistributor' : 'pageFluidBalance.amountHandedOver'),
                                value: balance.amountHandedOver
                              }
                            ]}
                          />
                        </List>
                        <ButtonBlueLight
                          disabled={!yearSelected}
                          style={{ marginBottom: '2rem' }}
                          onClick={(e) => { e.stopPropagation(); exportReport(balance.fluid); }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} />
                          {translate('pageFluidBalance.buttonExport')}
                        </ButtonBlueLight>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </GenericListContainer>
  );
});
